import React from "react";
import { Query } from "react-apollo";
import InputSelect from "../UI/Input/Select";
import gql from "graphql-tag"

const LIST_JOBS = gql`
    query{
        listJobs{
            id
            title
        }
    }
`


const Container = ({onChange,...rest}) => (
  <Query query={LIST_JOBS}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;
     let jobs = data.listJobs

     
      return (
        <InputSelect
        options={jobs.map(job =>{
          return(
            {text: `${job.title}`,value:job.id}
          )
        }
        ).filter(c => c)}
        onChange={value => onChange(value)}
        defaultText="-- Select job --"
        {...rest}
        />
        
      )
    }}
  </Query>
);

export default Container;
