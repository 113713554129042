import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { SIGN_UP_CAREGIVER } from "../../../graphql";
import Text from "../../Type/Text";
import Button from "../../UI/Button";
import InputText from "../../UI/Input/Text"
import Select from "../../UI/Input/Test/Select"
import moment from "moment"
import { Rowz, Spacer } from "../../Layout/Layout";

class CaregiverRegistrationForm extends React.Component {
  state = {
      email: "",
      password: "",
      passwordError: "",      
      firstName: "",
      lastName: "",
      birthDate: undefined,
      address: "",
      postalCode: "",
      livingPlace: "",
      gender: "",
      phoneNumber: "",
      platform: "",
      platformStartDate: undefined
  };

  genderTypes = [
    { text: "Male", value: "Male" },
    { text: "Female", value: "Female" },
    { text: "Other", value: "Other" }
  ];

  verifyPassword = (password)=>{
    

    if(password.length < 6){
      this.setState({passwordError : "Password must be at least 6 characters long"})
      return false;
    }

    if (!/[A-Z]+/.test(password)) {
      this.setState({passwordError: "Password must include one uppercase character"})
      return false;
    }

    if (!/[0-9]+/.test(password)) {
      this.setState({passwordError: "Password must include one numeric character"})
      return false;
    }

    if (!/[^0-9A-Za-z]+/.test(password)) {
      this.setState({passwordError: "Password must include one special character"})
      return false;
    }

    this.setState({passwordError: ""})

    return true;
  }

  handleInputChange = (name, value) => {
    if(name === "password"){
      if(this.verifyPassword(value)){
        this.setState({
          [name]: value
        });
      }
    }else{
      this.setState({
        [name]: value
      });
    }
    
  };
  
  render() {
    return (
      <Mutation mutation={SIGN_UP_CAREGIVER}>
        {(signUpCaregiver, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.signUpCaregiver) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `New caregiver registered succesfully`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();

                  // Input receives all the properties of state except passwordError
                  const {passwordError, ...input} = this.state
                  
                  signUpCaregiver({ variables: { input } });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>First name:</Text>
                      <InputText
                        name="firstName"
                        placeholder="Luuk"
                        required
                        onChange={value =>
                          this.handleInputChange("firstName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Last name:</Text>
                      <InputText
                        name="lastName"
                        placeholder="Akkerman"
                        required
                        onChange={value =>
                          this.handleInputChange("lastName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Gender:</Text>
                      <Select
                        name="gender"
                        defaultText="-- Select --"
                        options={this.genderTypes}
                        onChange={value =>
                          this.handleInputChange("gender", value)
                        }
                      />
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Rowz>
                      <Text>Email:</Text>
                      <InputText
                        name="email"
                        placeholder="user@tibu.nu"
                        required
                        onChange={value =>
                          this.handleInputChange("email", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <div>{this.state.passwordError}</div>
                      <Text>Password:</Text>
                      <InputText
                        name="password"                    
                        required
                        password
                        title=" Password length must be greater than 8 &#013; Password must contain a capital letter &#013; Password must contain a number &#013; Password must contain a special character "
                        onChange={value =>
                          this.handleInputChange("password", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  
                  <Spacer>
                    <Rowz>
                      <Text>Birthdate:</Text>
                      <InputText
                        name="birthdate"
                        required
                        date
                        onChange={value =>{
                            this.handleInputChange("birthdate", moment(value).unix())
                        }
                            
                        }
                      />
                    </Rowz>
                  </Spacer>  
                  <Spacer>
                    <Rowz>
                      <Text>Address:</Text>
                      <InputText
                        name="address"
                        placeholder="Boris Pasternakstraat 123"
                        required
                        onChange={value =>
                          this.handleInputChange("address", value)
                        }
                      />
                    </Rowz>
                  </Spacer>                  
                  <Spacer>
                    <Rowz>
                      <Text>Postal Code:</Text>
                      <InputText
                        name="postalCode"
                        placeholder="1102TD"
                        required
                        onChange={value =>
                          this.handleInputChange("postalCode", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Living Place:</Text>
                      <InputText
                        name="livingPlace"
                        placeholder="Amsterdam"
                        required
                        onChange={value =>
                          this.handleInputChange("livingPlace", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Phone number:</Text>
                      <InputText
                        name="phoneNumber"
                        placeholder="0666112233"
                        onChange={value =>
                          this.handleInputChange("phoneNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Platform:</Text>
                      <InputText
                        name="platform"
                        placeholder="De Tamarinde"
                        onChange={value =>
                          this.handleInputChange("platform", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
									<Spacer>
                    <Rowz>
                      <Text>Start date in platform:</Text>
                      <InputText
                        name="platformStartDate"
                        required
                        date
                        onChange={value =>{
                            this.handleInputChange("platformStartDate", moment(value).unix())
                        }
                            
                        }
                      />
                    </Rowz>
                  </Spacer>									
                  <Spacer>
                    <Button type="submit" disabled={loading || this.state.passwordError}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default CaregiverRegistrationForm;
