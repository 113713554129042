import React from "react";
import Header from "../../components/Header";
import LocationProfileContainer from "../../components/Location/Profile/Container";

const Location = ({ match }) => (
  <React.Fragment>
    <Header />
    <div>
      Location page
      <LocationProfileContainer locationID={match.params.id} />
    </div>
  </React.Fragment>
);

export default Location;