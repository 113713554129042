import React from "react";
import "../../../css/base.scss";
import "../../../css/variables.scss";
import "../../../css/mixins.scss";
import styles from "./Button.module.scss";

import { ButtonStyle } from "../../Layout/Layout";

const Button = ({
  primary: isPrimary,
  type = "button",
  children,
  link = ButtonStyle.default,
  status = ButtonStyle.default,
  iActive = ButtonStyle.interactive,
  primaryStyle = ButtonStyle.primary,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`${
        styles.tb_l_button
      } ${primaryStyle} ${link} ${status} ${iActive}`}
      type={type}
    >
      <span className={`${styles.tb_l_button__text}`}>{children}</span>
    </button>
  );
};

export default Button;