import React from "react";
import { Query } from "react-apollo";
import Studies from "../UI/Studies";
import gql from "graphql-tag"

export const GET_USER_STUDIES = gql`
  query ($userID: ID!){
    getUser (userID : $userID){
      id
      studies{
        id
        startMonth
        startYear
        endMonth
        endYear
        studies
        present
        school
      }
    }
  }
`;

const compareDates = (a,b) => {
  if(a.endYear){
   if(b.endYear){
     if(a.endYear > b.endYear){
       return 1
     }else if(a.endYear < b.endYear){
       return -1
     }else{
       return 0
     }
   }else{
     return -1
   }
  }else{
    if(b.endYear){
      return 1
    }else{
      return 0
    }
  }
}

const Container = ({userID,...rest}) => (
    <Query query={GET_USER_STUDIES} variables={{userID}}>
    {
      ({error,loading, data})=>{
        if(error) return "error :("
        if(loading) return "loading"
        if(data.getUser){

          let studies = data.getUser.studies;

          if(studies){
            studies = studies.sort(compareDates)
            studies = studies.reverse()
          }
          
          return(
            <Studies studiesEntries={studies || []}/>
          )
        }
      }
    }
    </Query>
);

export default Container;




