import React from "react";
import { Query } from "react-apollo";
import { LIST_REGIONS } from "../../../graphql";
import InputSelect from "../../UI/Input/Select";

const Container = ({onChange, ...rest}) => (
  <Query query={LIST_REGIONS}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;
     const regions = data.listRegions
      return (
        <InputSelect
        options={regions.map(region =>{
          return(
            {text: region.name,value:region.id}
          )
        }
        ).filter(c => c)}
        onChange={value => onChange(value)}
        defaultText="-- Select region --"
        {...rest}
        />
        
      )
    }}
  </Query>
);

export default Container;
