import React from "react";
import { Query } from "react-apollo";
import { GET_LOCATION } from "../../../graphql";
import UpdateForm from "./UpdateForm"

// Fetches current user data to be pass as default values for the update user form
const Container = ({locationID}) => (
    <Query query={GET_LOCATION} variables={{id: locationID}}>{
        ({data,error,loading})=>{
            if(error)return "Error :("
            if(loading) return "Loading ..."

            if(data.getLocation){
                return <UpdateForm locationID={locationID} locationData={data.getLocation} organizationID={data.getLocation.organization.id}/>
            }
        }
        
    }
    </Query>

)

export default Container