import React from "react";
import JobRegistrationForm from "../components/JobRegistrationForm/JobRegistrationForm"
import FormPage from "./FormPage"

const JobRegistration = () => (
    <React.Fragment>
      <FormPage title="Create a job" form={<JobRegistrationForm/>} />    
    </React.Fragment>    
)

export default JobRegistration;
