import React from "react";
import {
  Card,
  Spacer
} from "../../Layout/Layout";

import ProfileBrief from "../../UI/Profile/Brief";

import { Type, TextBold, TextSize } from "../../Type/Type";

const Profile = ({ patient, region }) => {
  const listLeft = [];
  const listRight = [];

  listLeft.push({ name: "First name", value: patient.firstName });
  listLeft.push({ name: "Last name", value: patient.lastName });
  listLeft.push({ name: "Reference number", value: patient.refNumber });

  listRight.push({ name: "Organization", value: region.organization.name });
  listRight.push({ name: "Region", value: region.name });

  return (
    <Spacer>
      <Card>
        <Spacer>
          <Spacer>
          <Type size={TextSize.lg} boldness={TextBold.bold}>
            {patient.name}
          </Type>
          </Spacer>          
          <ProfileBrief listLeft={listLeft} listRight={listRight} />
        </Spacer>
      </Card>
    </Spacer>
  );
};

export default Profile;
