import React from "react";
import AvatarDefault from "./account.svg";
import Text from "../../Type/Text";
import styles from "./Tavatar.module.scss";
import { Floaters, Spacers } from "../../../components/Layout/Layout";
import { Link } from "react-router-dom";

export const AvatarBlock = {
  default: "tb-avatar-size--default",
  medium: "tb-avatar-size--Medium",
  big: "tb-avatar-size--big"
};

function Tavatar(props) {
  const {
    noLink,
    firstName,
    lastName,
    url,
    float = Floaters.none,
    spacer = Spacers.sSmaller
  } = props;

  const src = url || AvatarDefault;

  return (
    <div
      className={`${styles.tb_l_avatar_block} ${
        styles.tb_l_avatar_block_small
      } ${float} ${spacer}`}
    >
      <div className={`${styles.tb_l_avatar} ${styles.tb_l_avatar__small}`}>
        <img
          className={`${styles.tb_l_avatar__img}`}
          src={src}
          alt={`${firstName} avatar`}
        />
      </div>
      <div className={`${styles.tb_l_avatar_block__text}`}>
        <span className={`${styles.tb_l_avatar_block__title}`}>
          {noLink ? (
            <Text spacer={Spacers.resetb}>{firstName + " " + lastName}</Text>
          ) : (
            <Link to="/profile">
              <Text spacer={Spacers.resetb}>{firstName + " " + lastName}</Text>
            </Link>
          )}
        </span>
      </div>
    </div>
  );
}

export default Tavatar;
