import React, { useState } from "react";
import PatientProfile from "../../../components/Patient/Profile/Profile";
import Header from "../../../components/Header";
import ProfileBannerContainer from "../../../components/Profile/Banner/Container";
import { Query } from "react-apollo";
import { Type, TextBold, TextSize } from "../../../components/Type/Type";
import OrganizationSelect from "../../../components/Organization/Select/Container";
import Text from "../../../components/Type/Text"

import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../../components/Layout/Layout";
import { LIST_PATIENTS, GET_ORGANIZATION } from "../../../graphql";

const PatientList = ({ match }) => {
  const [organizationID, setOrganizationID] = useState("");

  return (
    <React.Fragment>
      <Header />
        
      <Rowz>
        <Wrapper>
          <Tgrid gr={tbGrids.grid20}>
            <Card>
              <Spacer>
                <ProfileBannerContainer />
              </Spacer>
            </Card>
          </Tgrid>
          <Tgrid gr={tbGrids.grid80}>
            <Spacer spacer={Spacers.sLargeL}>
              <Card>
                <Spacer>
                  <Type size={TextSize.lg} boldness={TextBold.bold}>
                    Patients
                  </Type>
                </Spacer>
                <Spacer>
                    <Rowz>
                      <Text>Organization:</Text>
                      <OrganizationSelect
                  onChange={orgID => setOrganizationID(orgID)}
                />
                    </Rowz>
                  </Spacer>
                

                {organizationID ? (
                  <Query
                    query={GET_ORGANIZATION}
                    variables={{ id: organizationID }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return "loading...";
                      if (error) return "error :(";

                      if (data.getOrganization) {
                        console.log("data.getOrgania:" + JSON.stringify(data.getOrganization,null,2))
                        return data.getOrganization.regions.map(
                          (region) => (
                              region.patients.map((patient,idx)=><PatientProfile key={idx} patient={patient} region={region} />)
                            
                          )
                        );
                      }
                    }}
                  </Query>
                ) : (
                  <Query query={LIST_PATIENTS}>
                    {({ loading, error, data }) => {
                      if (loading) return "loading...";
                      if (error) return "error :(";

                      if (data.listPatients) {
                        
                        return data.listPatients.map((patient, idx) => (
                          <PatientProfile key={idx} patient={patient} region={patient.region} />
                        ));
                      }
                    }}
                  </Query>
                )}
              </Card>
            </Spacer>
          </Tgrid>
        </Wrapper>
      </Rowz>
    </React.Fragment>
  );
};

export default PatientList;
