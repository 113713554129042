import React, { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "./ResponsiveTable";
import styles from "./Table.module.scss";

function TableComponent(props) {
  const {
    headers = [],
    rows = [],
    handleHeaderClick = () => false
  } = props;
  const [selectedHeader, setSelectedHeader] = useState();

  function handleClick(headerName) {
    handleHeaderClick(headerName);
    setSelectedHeader(headerName);
  }

  return (
    <div className={`${styles.tb_l_table_container}`}>
      <Table className={`${styles.tb_l_table}`}>
        <Thead>
          <Tr className={`${styles.tb_l_table_row} ${styles.head}`}>
            {headers.map(header => {
              let headerName = header;
              let toggleable = false;
              if(typeof header === "object") {
                headerName = header.text;
                toggleable = header.toggleable;
              }

              return toggleable ? (
                <Th
                  key={headerName}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline"
                  }}
                  onClick={() => handleClick(headerName)}
                >
                  {headerName} {selectedHeader === headerName && "\u25BC"}
                </Th>
              ) : (
                <Th key={headerName}>{headerName}</Th>
              ); 
            })}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row, i) => (
            <Tr className={styles.tb_l_table_row} key={row.key || i}>
              {row.cols.map((col, i) => (
                <Td key={col.key || i}>{col.content}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}

export default TableComponent;
