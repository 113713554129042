import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { CREATE_LOCATION } from "../../../graphql";
import InputText from "../../UI/Input/Text";
import Text from "../../Type/Text";
import Button from "../../UI/Button";
import OrganizationSelect from "../../Organization/Select/Container"

import {
  Rowz,
  Spacer
} from "../../../components/Layout/Layout";

class LocationRegistrationForm extends React.Component {
  state = {
    name: "",
    address: "",
    place: "",
    refNumber: "",
    postalCode: "",
    organizationID: ""
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  
  render() {
    return (
      <Mutation mutation={CREATE_LOCATION}>
        {(createLocation, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.createLocation) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Location ${
                        data.createLocation.name
                      } successfully created`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createLocation({ variables: { input: this.state } });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Name:</Text>
                      <InputText
                        name="name"
                        placeholder="Hoofddorp Toolenburg"
                        required
                        onChange={value =>
                          this.handleInputChange("name", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Address:</Text>
                      <InputText
                        name="name"
                        placeholder="Toolengstraat 123A"
                        required
                        onChange={value =>
                          this.handleInputChange("address", value)
                        }
                      />
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Rowz>
                      <Text>Postal Code:</Text>
                      <InputText
                        name="postalCode"
                        placeholder="2011GM"
                        required
                        onChange={value =>
                          this.handleInputChange("postalCode", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Place:</Text>
                      <InputText
                        name="place"
                        placeholder="Zuid"
                        required
                        onChange={value =>
                          this.handleInputChange("place", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Reference number:</Text>
                      <InputText
                        name="refNumber"
                        placeholder="12345"
                        required
                        onChange={value =>
                          this.handleInputChange("refNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <OrganizationSelect required onChange={value=>this.handleInputChange("organizationID",value)}/>
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default LocationRegistrationForm;