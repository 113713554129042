import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import InputText from "../UI/Input/Text";
import InputSelect from "../UI/Input/Select";
import Text from "../Type/Text";
import Button from "../UI/Button";
import gql from "graphql-tag";
import OrganizationSelect from "../Organization/Select/Container";
import LocationSelect from "../Location/Select/Container";
import PatientSelect from "../Patient/Select/Container";

import { Rowz, Spacer } from "../Layout/Layout";

const CREATE_JOB = gql`
  mutation createJob($jobInput: JobInput!) {
    createJob(jobInput: $jobInput) {
      id
      title
    }
  }
`;

class JobRegistrationForm extends React.Component {
  state = {
    title: "",
    description: "",
    organizationID: null,
    patientID: null,
    locationID: null,
    peopleNeeded: null,
    workItemType: ""
  };

  renderSelect() {
    switch (this.state.workItemType) {
      case "PATIENT":
        return (
          <PatientSelect
            required
            onChange={value => this.handleInputChange("patientID", parseInt(value))}
          />
        );
      case "LOCATION":
        return (
          <LocationSelect
            required
            onChange={value => this.handleInputChange("locationID", parseInt(value))}
          />
        );
      default:
        return <InputSelect required disabled />;
    }
  }

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  workItemTypes = [
    { text: "Patient", value: "PATIENT" },
    { text: "Location", value: "LOCATION" }
  ];
  render() {
    return (
      <Mutation mutation={CREATE_JOB}>
        {(createJob, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.createJob) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Job ${data.createJob.title} successfully created`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();

                  const {
                    title,
                    description,
                    organizationID,
                    patientID,
                    locationID,
                    peopleNeeded
                  } = this.state;


                  if(!locationID && !patientID){
                      alert("You must choose either a patient or location")
                  }else{
                      console.log("creating job")
                      console.log('state:'+JSON.stringify(this.state,null,2));
                      
                    createJob({ variables: { jobInput: {title,
                        description,
                        organizationID,
                        patientID,
                        locationID,
                        peopleNeeded} } });
                  }

                  
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Title:</Text>
                      <InputText
                        name="title"
                        placeholder="Job in Zuid"
                        required
                        onChange={value =>
                          this.handleInputChange("title", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Description:</Text>
                      <textarea
                        cols="103"
                        required
                        onChange={e =>
                          this.handleInputChange("description", e.target.value)                      
                          
                        }
                      ></textarea>
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>People needed:</Text>
                      <input
                        type="number"
                        required
                        onChange={e =>
                          this.handleInputChange("peopleNeeded", parseInt(e.target.value))
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Organization:</Text>
                      <OrganizationSelect
                        onChange={value =>
                          this.handleInputChange("organizationID", parseInt(value))
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Work item type:</Text>
                      <InputSelect
                        defaultText="-- Work item type --"
                        options={this.workItemTypes}
                        onChange={value =>
                          this.handleInputChange("workItemType", value)
                        }
                        required
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Text>Work item:</Text>
                    <Rowz>{this.renderSelect()}</Rowz>
                  </Spacer>

                  <Spacer>
                    <Button
                      type="submit"
                      disabled={
                        loading 
                      }
                    >
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default JobRegistrationForm;
