import gql from "graphql-tag";

export const LOGIN = gql`
  mutation logIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      user {
        id
        firstName
        lastName
        email
        role
      }
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;

export const REGISTER_USER_CLIENT = gql`
  mutation($userID:ID!, $clientType:String!, $clientID:ID!){
  registerUserClient(userID:$userID,clientType:$clientType,clientID: $clientID){
    id
    firstName
    lastName
    role
  }
}
`
export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: OrganizationInput!){
  createOrganization(organizationInput:$input){
    id
    name
    email
  }  
}

`;

export const REGISTER_PATIENT = gql`
  mutation($input: PatientInput!){
  registerPatient(patientInput: $input){
    id
    firstName
    lastName
    refNumber
    region{
      id
      name
    }
  }
}
`

export const GET_PATIENT = gql`
  query getPatient($id: ID!) {
    getPatient(id: $id) {
      id
      firstName
      lastName
      refNumber
      region{
        id
        name
      }
      
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation deletePatient($id: ID!) {
    deletePatient(id: $id) {
      id
      firstName
      lastName    
    }
  }
`;


export const LIST_LOCATIONS = gql`
  query {
    listLocations {
      id
      address
      name
      postalCode
      refNumber
      place
      organization{
        id
        name
      }
    }
  }
`;

export const LIST_USERS = gql`
  query{
    listUsers{
      id
      firstName
      lastName
      role
      email
    }
  }
`

export const LIST_PATIENTS = gql`
  query{
  listPatients{
    id
   firstName
    lastName
    refNumber
    region{
      id
      name
      organization{
        id
        name
      }
    }
  }
}
`

export const LIST_ORGANIZATIONS = gql`
  query listOrgs($type: OrgType) {
    listOrganizations(type: $type) {
      id
      name
      address
      postalCode
      place      
      phoneNumber
      refNumber
      email
    }
  }
`;



export const LIST_REGIONS = gql`
  query {
    listRegions {
      id
      name
      place
      organization{
        id
        name
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query getOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      address
      postalCode
      phoneNumber
      email
      refNumber
      place
      caregivers{
        id
        firstName
        lastName
        email
      }
      managers{
        id
        firstName
        lastName
        email
      }
      regions{
        id
        name
        place
        refNumber
        organization{
          id
          name
        }
        patients{
          id
          firstName
          lastName
          refNumber          
        }
        
      }
      locations{
        id
        name
        address
        postalCode
        place
        refNumber
        organization{
          id
          name
        }
      }
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($id: ID!) {
    deleteOrganization(id: $id) {
      id
      name     
    }
  }
`;

export const GET_LOCATION = gql`
  query getLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      address
      place
      refNumber
      postalCode
      caregivers{
        id
        firstName
        lastName
        email
      }
      managers{
        id
        firstName
        lastName
        email
      }
      organization{
        id
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
      name     
    }
  }
`;

export const GET_REGION = gql`
  query getRegion($id: ID!) {
    getRegion(id: $id) {
      id
      name
      place
      refNumber
      organization{
        id
        name
      }
      caregivers{
        id
        firstName
        lastName
        email
      }
      managers{
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const DELETE_REGION = gql`
  mutation deleteRegion($id: ID!) {
    deleteRegion(id: $id) {
      id
      name     
    }
  }
`;

export const CREATE_REGION = gql`
  mutation createRegion($input: RegionInput!){
  createRegion(regionInput:$input){
    id
    name
  }
  
}
`;

export const CREATE_LOCATION = gql`
  mutation createLocation($input: LocationInput!){
  createLocation(locationInput:$input){
    id
    name
  }
  
}
`;

export const CREATE_LOCATION_MANAGER = gql`
  mutation assignManagerToLocation(
    $managerID: ID!,
    $locationID: ID!
  ) {
    assignManagerToLocation(managerID: $managerID, locationID: $locationID) {
      id
    }
  }
`;

export const CREATE_REGION_MANAGER = gql`
  mutation assignManagerToRegion(
    $managerID: ID!,
    $regionID: ID!
  ) {
    assignManagerToRegion(managerID: $managerID, regionID: $regionID) {
      id
    }
  }
`;

export const CREATE_ORGANIZATION_MANAGER = gql`
  mutation assignManagerToOrganization(
    $managerID: ID!,
    $organizationID: ID!
  ) {
    assignManagerToOrganization(managerID: $managerID, organizationID: $organizationID) {
      id
    }
  }
`;

export const ASSIGN_USER_TO_ORGANIZATION = gql`
  mutation assignUserToOrganization(
    $userID: ID!,
    $organizationID: ID!
  ) {
    assignUserToOrganization(userID: $userID, organizationID: $organizationID) {
      id
    }
  }
`;

export const CURRENT_USER = gql`
  query {
    currentUser{
        id
        email
        role
        firstName
        lastName
        avatarUrl
    }
  }
`;

export const GET_USER = gql`
  query ($userID : ID!){
    getUser(userID: $userID) {
      id
      email
      role
      firstName
      lastName
      avatarUrl
      bio
      phoneNumber
      birthdate
      address
      postalCode
      livingPlace
      drivingLicense
      position
      platform
      platformStartDate
      platformEndDate
      ownsCar
      gender
      organization{
        id
        name
      }
      studies{
        id
        endYear
        school
        studies
      }
      characteristics{
        id
        name
      }
      clients {
        id
        __typename
      }
    }
  }
`;

export const SIGN_UP = gql`
 mutation($input: NewUserInput!) {
  signUp(newUserInput: $input) {
    user {
      id
      firstName
      lastName
    }
  }
}

`;

export const UPDATE_LOCATION = gql`
mutation updateLocation($id: ID!,$input: LocationInput!){
  updateLocation(id: $id,locationInput: $input){
    id
    name
    
  }
}
`

export const UPDATE_REGION = gql`
mutation updateRegion($id: ID!,$input: RegionInput!){
  updateRegion(id: $id,regionInput: $input){
    id
    name
    
  }
}
`

export const UPDATE_PATIENT = gql`
mutation updatePatient($id: ID!,$input: PatientInput!){
  updatePatient(id: $id,patientInput: $input){
    id
    firstName
    lastName    
  }
}
`
export const UPDATE_ORGANIZATION = gql`
mutation updateOrganization($id: ID!,$input: OrganizationInput!){
  updateOrganization(id: $id,organizationInput: $input){
    id
    name
    
  }
}
`

export const SIGN_UP_CAREGIVER = gql`
mutation($input: NewCaregiverInput!){
  signUpCaregiver(newCaregiverInput: $input){
    id
  }
}
`

export const SIGN_UP_MANAGER = gql`
mutation($input: NewManagerInput!){
  signUpManager(newManagerInput: $input){
    id
  }
}
`