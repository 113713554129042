import React from "react"
import Text from "../../../Type/Text"
import {
    Spacers,
  } from "../../../Layout/Layout";

function Bio({text=""}){
    return(         
        <React.Fragment>
            {text.split('\n').map((paragraph,index)=>(<Text key={index} spacer={Spacers.sBMedium}>{paragraph}</Text>))}
        </React.Fragment>        
    )
}

export default Bio;