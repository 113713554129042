import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { CREATE_LOCATION_MANAGER } from "../../../graphql";
import Button from "../../UI/Button";
import LocationSelect from "../../Location/Select/Container";
import UserSelect from "../../User/Select/Container";

import {
  Rowz,
  Spacer
} from "../../../components/Layout/Layout";

class AssignManagerForm extends React.Component {
  state = {
    locationID: "",
    managerID: ""
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  
  render() {
    return (
      <Mutation mutation={CREATE_LOCATION_MANAGER}>
        {(createLocationManager, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.assignManagerToLocation) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Manager assigned to location successfully`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createLocationManager({ variables: this.state });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <LocationSelect required value={this.state.locationID} onChange={value=>this.handleInputChange("locationID",value)}/>
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <UserSelect required value={this.state.managerID} filterRole="MANAGER" onChange={value => this.handleInputChange("managerID", value)} />
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default AssignManagerForm;