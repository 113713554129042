import React from "react";
import { Query } from "react-apollo";
import { GET_USER } from "../../../graphql";
import ProfileBrief from "../../UI/Profile/Brief";
import moment from "moment"

function getAgeFromBirthdate(birthdate){
  
  const today = moment()
  const birthday = moment(birthdate*1000)

  const duration = moment.duration(today.diff(birthday))
  const age = Math.trunc(duration.asYears())

  return age;
}
/**
 * 
 * @param {Object} userData User data fetched from the backend
 * @returns List of objects to be inserted in profile brief left side
 */
function createBriefLeftList(userData){

  getAgeFromBirthdate(userData.birthdate)

  const briefLeftList = [];

  briefLeftList.push({name: "First name", value: userData.firstName});
  briefLeftList.push({name: "Last name", value: userData.lastName});
  briefLeftList.push({name: "Email", value: userData.email});
    
  return briefLeftList
}

/**
 * 
 * @param {Object} userData User data fetched from the backend
 * @returns List of objects to be inserted in profile brief left side
 */
function createBriefRightList(userData){
  const briefRightList = [];
  
  briefRightList.push({name: "Phone number", value: userData.phoneNumber});
  briefRightList.push({name: "Organization", value: userData.organization ? userData.organization.name : ""});
  briefRightList.push({name: "Position", value: userData.position});
  if(userData.region){
    briefRightList.push({name: "Region", value: userData.region ? userData.region.name : ""});
  }
  if(userData.location){
    briefRightList.push({name: "Location", value: userData.location ? userData.location.name : ""});
  }
  
  
  return briefRightList
  
}

const ManagerContainer = ({userID,onClick,...rest}) => (
  <Query query={GET_USER} variables={{userID}}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;

      if (data.getUser) {
        const listLeft = createBriefLeftList(data.getUser);
        const listRight = createBriefRightList(data.getUser);
				return <ProfileBrief listLeft={listLeft} listRight={listRight} onClick={onClick} {...rest} />;
				
      }
    }}
  </Query>
);

export default ManagerContainer;
