import React from "react";
import DefaultAvatar from "./account.svg";
import styles from "./Banner.module.scss";
function ProfileBanner(props) {
  const { firstName, lastName, role = "MANAGER", url } = props;

  const src = url || DefaultAvatar;

  return (
    <div className={`${styles.tb_l_spacer__large}`}>
      <div className={`${styles.tb_l_avatar_block_main}`}>
        <img src={src} alt={`${firstName} avatar`} />
      </div>
      <div className={`${styles.tb_l_profile__name}`}>
        {firstName + " " + lastName}
        <span className={`${styles.tb_l_profile__name_jtitle}`}>{role}</span>
      </div>
      <ul style={{fontWeight: "lighter", fontSize: 17}}>{props.children}</ul>      
    </div>
  );
}

export default ProfileBanner;
