import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation,Query } from "react-apollo";
import { REGISTER_PATIENT, GET_ORGANIZATION } from "../../../graphql";
import InputText from "../../UI/Input/Text";
import Text from "../../Type/Text";
import Button from "../../UI/Button";
import Select from "../../UI/Input/Test/Select"
import OrganizationSelect from "../../Organization/Select/Container"


import {
  Rowz,
  Spacer
} from "../../../components/Layout/Layout";

class PatientRegistrationForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    organizationID:"",
    regionID: "",
    refNumber: ""
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  
  render() {
    return (
      <Mutation mutation={REGISTER_PATIENT}>
        {(registerPatient, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.registerPatient) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Patient ${
                        data.registerPatient.firstName
                      } ${
                        data.registerPatient.lastName
                      } successfully registered in ${data.registerPatient.region.name}`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  const {firstName, lastName, regionID, refNumber} = this.state
                  registerPatient({ variables: { input: {firstName,lastName,regionID, refNumber} } });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>First name:</Text>
                      <InputText
                        name="firstName"
                        placeholder="Luuk"
                        required
                        onChange={value =>
                          this.handleInputChange("firstName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Last name:</Text>
                      <InputText
                        name="lastName"
                        placeholder="Akkerman"
                        required
                        onChange={value =>
                          this.handleInputChange("lastName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                  <Rowz>
                      <Text>Reference number:</Text>
                      <InputText
                        name="refNumber"
                        placeholder="12345"
                        required
                        onChange={value =>
                          this.handleInputChange("refNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Rowz>
                      <OrganizationSelect required onChange={value=>this.handleInputChange("organizationID",value)}/>
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Rowz>
                      {this.state.organizationID ? <Query
                    query={GET_ORGANIZATION}
                    variables={{ id: this.state.organizationID }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return "loading...";
                      if (error) return "error :(";

                      if (data.getOrganization) {
                        const {regions} = data.getOrganization
                        return <Select required defaultText="--Select region--" onChange={value=>this.handleInputChange("regionID",value)} options={regions.map((region)=>{return {text : region.name, value: region.id}})} />
                        
                      }
                    }}
                  </Query> : ""}
                                            
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default PatientRegistrationForm;