import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/UI/Header";
import CaregiverBannerConainer from "../../components/Profile/Banner/Caregiver/Container"
import ManagerBannerContainer from "../../components/Profile/Banner/Manager/Container"
// import TextInput from "../../components/UI/Input/Text";
import AvatarContainer from "../../components/Avatar/Container";
import { Type, TextBold, TextSize } from "../../components/Type/Type";
import Heading from "../../components/Type/Heading";
import Tlogo from "../../components/UI/Logo";
import CaregiverProfileBriefContainer from "../../components/Profile/Brief/CaregiverContainer";
import WorkExperienceContainer from "../../components/WorkExperience/Container";
import BioContainer from "../../components/Profile/Bio/Container";
import StudiesContainer from "../../components/Studies/Container";
import { GET_USER } from "../../graphql";
import { Query } from "react-apollo";
import Button from "../../components/UI/Button"
import ManagerProfileBriefContainer from "../../components/Profile/Brief/ManagerContainer";
import Logout from "../../components/Logout/Logout";

import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids,
  Floaters
} from "../../components/Layout/Layout";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: props.match.params.userID,
      role: ""
    };
  }

  render() {
    return (
      <Query query={GET_USER} variables={{ userID: this.state.userID }}>
        {({ data, error, loading }) => {
          if (error) return "error :(";
          if (loading) return "loading...";
          if (data.getUser) {
            const characteristics = data.getUser.characteristics;
            const role = data.getUser.role;
            console.log("User ID: " + this.state.userID)
            return (
              <React.Fragment>
                <Header>
                  <Tgrid gr={tbGrids.grid20}>
                    <Link to="/">
                      <Tlogo portalName="Admin portal" />
                    </Link>
                  </Tgrid>
                  <Tgrid gr={tbGrids.grid40}>
                    <Spacer spacer={Spacers.sLargeL}>
                      {/* <InputText pslaceholder="Search Tibu" /> */}
                    </Spacer>
                  </Tgrid>
                  <Tgrid gr={tbGrids.grid30}>
                    <Spacer spacer={Spacers.sLargeL}>
                      <AvatarContainer float={Floaters.right} />
                    </Spacer>
                  </Tgrid>
                  <Tgrid gr={tbGrids.grid10}>
                    <Spacer spacer={Spacers.sLargeL}>
                      <Logout />
                    </Spacer>
                  </Tgrid>
                </Header>
                <Rowz>
                  <Wrapper>
                    <Tgrid gr={tbGrids.grid20}>
                      <Card>
                        <Type size={TextSize.lg} boldness={TextBold.bold}>
                        {role === "MANAGER" ? <ManagerBannerContainer userID={this.state.userID} /> : <CaregiverBannerConainer userID={this.state.userID} /> }
                          
                        </Type>
                      </Card>
                    </Tgrid>
                    <Tgrid gr={tbGrids.grid80}>
                      <Spacer spacer={Spacers.sLargeL}>
                        <Heading>Profile details </Heading>
                        <Card>
                          <Spacer>
                            {role === "MANAGER" ? (
                              <ManagerProfileBriefContainer
                                userID={this.state.userID}
                              />
                            ) : (
                              <CaregiverProfileBriefContainer
                                userID={this.state.userID}
                              />
                            )}
                          </Spacer>
                        </Card>
                        {role === "CAREGIVER" ? (
                          <React.Fragment>
                            <Heading>Bio</Heading>
                            <Card>
                              <Spacer>
                                <BioContainer userID={this.state.userID} />
                              </Spacer>
                            </Card>
                            <Rowz bottom={Spacers.sBMedium}>
                              <Tgrid gr={tbGrids.grid50}>
                                <Heading>Characteristics </Heading>
                              </Tgrid>
                            </Rowz>

                            <Card>
                            <Spacer>
                            <Rowz>
                            {characteristics.map(characteristic => (
                              <Tgrid
                              key={characteristic.id}
                              gr={tbGrids.grid20}
                            >
                            <Spacer>
                            <Button disabled style={{backgroundColor : "#4CAF50" }} key={characteristic.id}>
                                {characteristic.name}
                              </Button>
                            </Spacer>
                            </Tgrid>                              
                            ))}
                            </Rowz>
                            
                          </Spacer>
                            </Card>
                            <Heading>Work Experience </Heading>
                            <Card>
                              <Spacer>
                                <WorkExperienceContainer
                                  userID={this.state.userID}
                                />
                              </Spacer>
                            </Card>

                            <Heading>Studies </Heading>
                            <Card>
                              <Spacer>
                                <StudiesContainer userID={this.state.userID} />
                              </Spacer>
                            </Card>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </Spacer>
                    </Tgrid>
                  </Wrapper>
                </Rowz>
              </React.Fragment>
            );
          }
        }}
      </Query>
    );
  }
}

export default Profile;
