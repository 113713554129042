import React from "react";
import { Query } from "react-apollo";
import { GET_ORGANIZATION } from "../../../graphql";
import UpdateForm from "./UpdateForm"

// Fetches current user data to be pass as default values for the update user form
const Container = ({organizationID}) => (
    <Query query={GET_ORGANIZATION} variables={{id: organizationID}}>{
        ({data,error,loading})=>{
            if(error)return "Error :("
            if(loading) return "Loading ..."

            if(data.getOrganization){
                console.log("data.getOrganization:" + JSON.stringify(data.getOrganization,null,2))
                return <UpdateForm organizationData={data.getOrganization} organizationID={organizationID}/>
            }
        }
        
    }
    </Query>

)

export default Container