import React from "react";
import { Query } from "react-apollo";
import { GET_USER } from "../../../graphql";
import Bio from "../../UI/Profile/Bio"

const Container = ({userID,...rest}) => (
  <Query query={GET_USER} variables={{userID}} >
    {({ loading, error, data }) => {
      if(loading) return <p>Loading...</p>;
      if(error) return <p>Error :(</p>;
    
        if(data.getUser){
            return (
                <Bio text={data.getUser.bio || ""} {...rest}/>
            );
        }
      
    }}
  </Query>
);

export default Container;