import React, { useState } from "react";
import { withApollo } from "react-apollo";
import moment from "moment";
import Card from "../../../components/UI/Card";
import TableComponent from "../../../components/UI/Table";
import { Link } from "react-router-dom";

function Table(props) {
  const { entries = [] } = props;
  const [selectedOrder, setSelectedOrder] = useState("startTime");

  const ORDER_OPTIONS = [
    { text: "Work item", value: "clientName" },
    { text: "Start time", value: "startTime" }
  ];

  const headers = [
    { text: "Work item", toggleable: true },
    "Last edited by",
    "Organization",
    "Caregiver",
    "Date",
    { text: "Start time", toggleable: true },
    "End time",
    "Total time"
  ];

  function renderStatus(status) {
    switch (status) {
      case "APPROVED":
        return `(Approved)`;
      case "DECLINED":
        return `(Declined)`;
      default:
        return "";
    }
  }

  entries.sort((a, b) => {
    switch (selectedOrder) {
      case "clientName":
        return a.clientName > b.clientName ? 1 : -1;
      default:
        if (+a[selectedOrder] < +b[selectedOrder]) return 1;
        else if (+a[selectedOrder] > +b[selectedOrder]) return -1;
        else return +a.id < +b.id ? 1 : -1;
    }
  });

  const rows = entries.map(entry => ({
    key: entry.id,
    cols: [
      {
        content: (
          <React.Fragment>
            {entry.job.title}
            {renderStatus(entry.status)}
          </React.Fragment>
        )
      },
      {
        content: entry.lastModifiedBy
          ? `${entry.lastModifiedBy.firstName} ${
              entry.lastModifiedBy.lastName
            }`
          : "N/A"
      },
      {
        content: `${entry.organization.name} (${
          entry.organization.refNumber
        })`
      },
      {
        content: (
          <Link to={`/profile/${entry.user.id}`}>{`${
            entry.user.firstName
          } ${entry.user.lastName}`}</Link>
        )
      },
      { content: moment.unix(entry.startTime).format("MMM Do, YYYY") },
      { content: moment.unix(entry.startTime).format("HH:mm") },
      { content: moment.unix(entry.endTime).format("HH:mm") },
      {
        content: moment
          .utc(
            moment
              .duration(entry.endTime - entry.startTime, "seconds")
              .as("milliseconds")
          )
          .format("HH:mm")
      }
    ]
  }));

  return (
    <Card>
      <TableComponent
        headers={headers}
        rows={rows}
        handleHeaderClick={header => {
          const sortOption = ORDER_OPTIONS.find(
            op => op.text === header
          );
          if (sortOption) {
            setSelectedOrder(sortOption.value);
          }
        }}
      />
    </Card>
  );
}

export default withApollo(Table);
