import React from "react";
import {
  Rowz,
  Wrapper
} from "../../Layout/Layout";
import styles from "./TopBar.module.scss";

const tbClasses = [
  `${styles.tb_l_top_bar} ${styles.tb_l_shadow__bottom} ${styles.tb_l_spacer}`
];
function TopBar(props) {
  return (
    <Rowz extraClassNames={tbClasses}>
      <Wrapper>{props.children}</Wrapper>
    </Rowz>
  );
}

export default TopBar;
