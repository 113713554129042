import React from "react";
import { Query } from "react-apollo";
import { CURRENT_USER, GET_USER } from "../../../graphql";
import ProfileBanner from "../../UI/Profile/Banner"
import ProfileEntry from "../../UI/Profile/Entry"

const Container = ({ userID }) => {
  let query;
  if(userID) {
    query = GET_USER;
  } else {
    query = CURRENT_USER;
  }

  return (
    <Query query={query} variables={{ userID }}>
      {({ loading, error, data }) => {
        if(loading) return <p>Loading...</p>;
        if(error) return <p>Error :(</p>;

        const user = userID ? data.getUser : data.currentUser;
        return (
            <ProfileBanner firstName={user.firstName} lastName={user.lastName} url={user.avatarUrl} userRole={user.role}>            
              <ProfileEntry icon="Mail">{user.email}</ProfileEntry>            
            </ProfileBanner>
        );
      }}
    </Query>
  )
};

export default Container;