import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { DELETE_PATIENT } from "../../../graphql";
import Button from "../../UI/Button";
import PatientSelect from "../../Patient/Select/Container"

import {
  Spacer,
  Tgrid,
  tbGrids
} from "../../../components/Layout/Layout";

class PatientDeleteForm extends React.Component {
  state = {
    patientID: ""
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  
  render() {
    return (
      <Mutation mutation={DELETE_PATIENT}>
        {(deletePatient, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.deletePatient) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Patient ${
                        data.deletePatient.firstName
                      } successfully deleted`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
                <form
                onSubmit={e => {
                  e.preventDefault();
                  deletePatient({ variables: { id: this.state.patientID } });
                }}
              >
                <Spacer>
                  
                  <Tgrid gr={tbGrids.grid70}>
                  <Spacer>
                      <PatientSelect required onChange={value=>this.handleInputChange("patientID",value)}/>
                    </Spacer>
                  </Tgrid>
                  <Tgrid gr={tbGrids.grid30}>
                  <Spacer><Button type="submit" disabled={loading}>
                      Delete
                    </Button></Spacer>

                  
                  </Tgrid>
                  
                </Spacer>
              </form>
              
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default PatientDeleteForm;