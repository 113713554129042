import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { REGISTER_USER_CLIENT } from "../../../graphql";
import Text from "../../Type/Text";
import Button from "../../UI/Button";
import OrganizationSelect from "../../Organization/Select/Container";
import LocationSelect from "../../Location/Select/Container";
import RegionSelect from "../../Region/Select/Container";
import UserSelect from "../Select/Container";
import PatientSelect from "../../Patient/Select/Container";
import InputSelect from "../../UI/Input/Select";

import { Rowz, Spacer } from "../../Layout/Layout";

class UserClientRegistrationForm extends React.Component {
  state = {
    userID: "",
    clientType: "",
    clientID: ""
  };

  clientTypes = [
    { text: "Patient", value: "PATIENT" },
    { text: "Region", value: "REGION" },
    { text: "Organization", value: "ORGANIZATION" },
    { text: "Location", value: "LOCATION" }
  ];

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  /**
   * Renders a select depeding on the client type selected by user
   * @returns Select component for client
   */
  renderSelect() {
    switch (this.state.clientType) {
      case "PATIENT":
        return (
          <PatientSelect
            required
            onChange={value => this.handleInputChange("clientID", value)}
          />
        );
      case "REGION":
        return (
          <RegionSelect
            required
            onChange={value => this.handleInputChange("clientID", value)}
          />
        );
      case "ORGANIZATION":
        return (
          <OrganizationSelect
            required
            type="HEALTHCARE"
            onChange={value => this.handleInputChange("clientID", value)}
          />
        );
      case "LOCATION":
        return (
          <LocationSelect
            required
            onChange={value => this.handleInputChange("clientID", value)}
          />
        );
      default:
        return <InputSelect required disabled />;
    }
  }

  render() {
    return (
      <Mutation mutation={REGISTER_USER_CLIENT}>
        {(registerUserClient, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.registerUserClient) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `New client successfully added to ${
                        data.registerUserClient.firstName
                      } ${data.registerUserClient.lastName}
                      `
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  registerUserClient({ variables: this.state });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>User:</Text>
                      <UserSelect
                        filterRole="CAREGIVER"
                        onChange={value =>
                          this.handleInputChange("userID", value)
                        }
                        required
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Client type:</Text>
                      <InputSelect
                        defaultText="-- Select client type --"
                        options={this.clientTypes}
                        onChange={value =>
                          this.handleInputChange("clientType", value)
                        }
                        required
                      />
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Text>Client:</Text>
                    <Rowz>{this.renderSelect()}</Rowz>
                  </Spacer>

                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default UserClientRegistrationForm;
