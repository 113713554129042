import React from "react";
import { Query } from "react-apollo";
import { LIST_PATIENTS } from "../../../graphql";
import InputSelect from "../../UI/Input/Select";

const Container = ({onChange,...rest}) => (
  <Query query={LIST_PATIENTS}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;
     const patients = data.listPatients
      return (
        <InputSelect
        options={patients.map(patient =>{
          return(
            {text: `${patient.firstName} ${patient.lastName}`,value:patient.id}
          )
        }
        ).filter(c => c)}
        onChange={value => onChange(value)}
        defaultText="-- Select patient --"
        {...rest}
        />
        
      )
    }}
  </Query>
);

export default Container;
