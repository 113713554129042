import React, {useState} from "react";
import { Query} from "react-apollo";
import gql from "graphql-tag";
import { AutoComplete } from "antd";
import 'antd/es/auto-complete/style/css';


const SEARCH_USER = gql`
  query($name: String) {
    searchUser(name: $name) {
      id
      firstName
      lastName
      email
    }
  }
`;

const SearchUser = ({onSelect}) => {

    const [searchText, setSearchText ] = useState("");
   
    const onSearch = (searchText) => {
        setSearchText(searchText)
    }

    const setData = (users) =>{
        return users.map(user => ({text: `${user.firstName} ${user.lastName}`, value:user.id}))
    }

    return(
        <Query query={SEARCH_USER} variables={{name:searchText}}>
            {({ loading, error, data }) =>{
                let dataSource = []
                if(!loading && !error && data.searchUser){            
                    dataSource = setData(data.searchUser)
                }
                return <AutoComplete onSearch={onSearch} dataSource={dataSource} onSelect={onSelect}/>;
            }}
        </Query>
        
    )
}

export default SearchUser;