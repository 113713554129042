import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { CREATE_ORGANIZATION_MANAGER } from "../../../graphql";
import Button from "../../UI/Button";
import OrganizationSelect from "../Select/Container";
import UserSelect from "../../User/Select/Container";

import {
  Rowz,
  Spacer
} from "../../../components/Layout/Layout";

class AssignManagerForm extends React.Component {
  state = {
    organizationID: "",
    managerID: ""
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  
  render() {
    return (
      <Mutation mutation={CREATE_ORGANIZATION_MANAGER}>
        {(createOrganizationManager, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.assignManagerToOrganization) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Manager assigned to organization successfully`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createOrganizationManager({ variables: this.state });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <OrganizationSelect required value={this.state.organizationID} onChange={value=>this.handleInputChange("organizationID",value)}/>
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <UserSelect required value={this.state.managerID} filterRole="MANAGER" onChange={value => this.handleInputChange("managerID", value)} />
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default AssignManagerForm;