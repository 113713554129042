import gql from "graphql-tag";

export const TIME_LOG_FRAGMENT = gql`
  fragment timeLog on TimeLogEntry {
    id
    user {
      id
      role
      firstName
      lastName
    }
    organization {
      id
      name
      refNumber
    }
   
   job{
     id
     title
   }
    approved
    status
    lastModifiedBy {
      id
      role
      firstName
      lastName
    }
    startTime
    endTime
  }
`;



export const GET_TIME_LOGS = gql`
  ${TIME_LOG_FRAGMENT}

  query getTimeLogs($startDate: Date, $endDate: Date) {
    listTimeLogEntries(startDate: $startDate, endDate: $endDate) {
      ...timeLog
    }
  }
`;

export const EXPORT_TIME_LOGS = gql`
  ${TIME_LOG_FRAGMENT}

  mutation exportTimeLogs($startDate: Date!, $endDate: Date!) {
    exportTimeLogEntries(startDate: $startDate, endDate: $endDate) {
      ...timeLog
    }
  }
`;