import React from "react";
import { Query } from "react-apollo";
import { GET_TIME_LOGS } from "../TimeOperations";
import Table from "./Table";

function Container(props) {
  const { startDate, endDate } = props;
  let parsedStartDate;
  if (startDate) {
    parsedStartDate = new Date(startDate);
    parsedStartDate.setHours(0);
    parsedStartDate.setMinutes(0);
    parsedStartDate.setSeconds(0);
  }

  let parsedEndDate;
  if (endDate) {
    parsedEndDate = new Date(endDate);
    parsedEndDate.setHours(0);
    parsedEndDate.setMinutes(0);
    parsedEndDate.setSeconds(0);
  }

  return (
    <Query
      query={GET_TIME_LOGS}
      variables={{
        startDate: parsedStartDate && parsedStartDate.getTime(),
        endDate: parsedEndDate && parsedEndDate.getTime()
      }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) return <p>loading...</p>;
        if (error) return <p>Error :(</p>;

        const entries = data.listTimeLogEntries || [];

        return <Table entries={entries} />;
      }}
    </Query>
  );
}

export default Container;
