import React from "react";
import { Query } from "react-apollo";
import { GET_PATIENT } from "../../../graphql";
import UpdateForm from "./UpdateForm"

// Fetches current user data to be pass as default values for the update user form
const Container = ({patientID}) => (
    <Query query={GET_PATIENT} variables={{id: patientID}}>{
        ({data,error,loading})=>{
            if(error)return "Error :("
            if(loading) return "Loading ..."

            if(data.getPatient){
                console.log("RegionID: "  +  data.getPatient.region.id)
                console.log("Ref number: " + data.getPatient.refNumber)
                return <UpdateForm patientID={patientID} patientData={data.getPatient} regionID={data.getPatient.region.id}/>
            }
        }
        
    }
    </Query>

)

export default Container