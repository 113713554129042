import React from "react";
import { Link, withRouter } from "react-router-dom";
// import InputText from "../../components/UI/Input/Text";
import Header from "../../components/Header";
import ProfileBannerContainer from "../../components/Profile/Banner/Container";
import Text from "../../components/Type/Text";
import SearchUser from "../../components/Search/User"

import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../components/Layout/Layout";

const Home = props => (
  <React.Fragment>
    <Header/>
    <Rowz>
      <Wrapper>
        <Tgrid gr={tbGrids.grid20}>
          <Card>
            <Spacer>
              <ProfileBannerContainer />
            </Spacer>
          </Card>
        </Tgrid>
        <Tgrid gr={tbGrids.grid80}>
          <Spacer spacer={Spacers.sLargeL}>
            {props.location.state ? (
              props.location.state.message ? (
                <Card>
                  <Spacer>{props.location.state.message}</Spacer>
                </Card>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            <Card>
              <Spacer>
                <Link to="/caregiver/register">Register a caregiver</Link>
              </Spacer>
              <Spacer>
                <Link to="/manager/register">Register a manager</Link>
              </Spacer>
              <Spacer>
                <Link to="/organization/register">
                  Register an organization
                </Link>
              </Spacer>
              <Spacer>
                <Link to="/organization/assignManager">Assign an organization manager</Link>
              </Spacer>
              <Spacer>
                <Link to="/organization/assignUser">Assign a platform caregiver</Link>
              </Spacer>
              <Spacer>
                <Link to="/region/register">Register a region</Link>
              </Spacer>
              <Spacer>
                <Link to="/region/assignManager">Assign a region manager</Link>
              </Spacer>

              <Spacer>
                <Link to="/location/register">Register a location</Link>
              </Spacer>
              <Spacer>
                <Link to="/location/assignManager">Assign a location manager</Link>
              </Spacer>
              <Spacer>
                <Link to="/patient/register">Register a patient</Link>
              </Spacer>
              
              <Spacer>
                <Link to="/job/register">
                  Create a job
                </Link>
              </Spacer>
              <Spacer>
                <Link to="/job/assign-caregiver">
                  Assign caregiver to a job
                </Link>
              </Spacer>
              <Spacer>
                <Link to="/job/remove-caregiver">
                  Remove caregiver from a job
                </Link>
              </Spacer>
              <hr className="tb_l_divider tb_l_spacer" />
              <Spacer>
                <Link to="/organizations">List organizations</Link>
              </Spacer>
              <Spacer>
                <Link to="/locations">List locations</Link>
              </Spacer>
              <Spacer>
                <Link to="/regions">List regions</Link>
              </Spacer>
              <Spacer>
                <Link to="/patients">List patients</Link>
              </Spacer>
              <Spacer>
                <Link to="/timesheet">List time log entries</Link>
              </Spacer>
              
              <hr className="tb_l_divider tb_l_spacer" />
              <Spacer>
                <Link to="/delete-panel">Delete Panel</Link>
              </Spacer>
              <Spacer>
                <Link to="/update-panel">Update Panel</Link>
              </Spacer>
              <Spacer>
                <Link to="/users">List users</Link>
              </Spacer>
              
              <Spacer>
              
                <Tgrid gr={tbGrids.grid40}>
                  <Text>View user profile</Text>
                  <SearchUser onSelect={(userID)=>props.history.push(`/profile/${userID}`)}/>
                  
                </Tgrid>
              </Spacer>
            </Card>
          </Spacer>
        </Tgrid>
      </Wrapper>
    </Rowz>
  </React.Fragment>
);

export default withRouter(Home);
