import React from "react";
import Header from "../../components/Header";
import RegionProfileContainer from "../../components/Region/Profile/Container";

const Region = ({ match }) => (
  <React.Fragment>
    <Header />
    <div>
      Region page
      <RegionProfileContainer regionID={match.params.id} />
    </div>
  </React.Fragment>
);

export default Region;