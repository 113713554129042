import React from "react";
import { Link } from "react-router-dom";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import Tavatar from "../UI/Tavatar/Tavatar";
import { Tgrid, tbGrids, Spacer, Spacers, Floaters } from "../Layout/Layout";
import Tlogo from "../UI/Logo";
import Button from "../UI/Button";
import TopBar from "../UI/TopBar";

function CurrentUser() {
  const GET_CURRENT_USER = gql`
    query getCurrentUser {
      currentUser {
        id
        email
        firstName
        lastName
        role
        avatarUrl
      }
    }
  `;

  return (
    <Query query={GET_CURRENT_USER}>
      {({ loading, error, data }) => {
        if (loading) return <p>loading...</p>;
        if (error) return <p>Error :(</p>;

        const { firstName, lastName, avatarUrl } = data.currentUser;

        return (
          <Tavatar
            noLink
            firstName={firstName}
            lastName={lastName}
            float={Floaters.right}
            url={avatarUrl}
          />
        );
      }}
    </Query>
  );
}

function LogoutBtn() {
  return (
    <Mutation
      mutation={gql`
        mutation logOut {
          logOut
        }
      `}
    >
      {logOut => {
        return (
          <Button
            onClick={() => {
              logOut().then(() => {
                localStorage.removeItem("t-rt");
                localStorage.removeItem("t-at");
                window.location.pathname = "/";
              });
            }}
          >
            Logout
          </Button>
        );
      }}
    </Mutation>
  );
}

function Header() {
  return (
    <TopBar>
      <Tgrid gr={tbGrids.grid20}>
        <Link to="/">
          <Tlogo portalName="Admin portal"  />
        </Link>
      </Tgrid>
      <Tgrid gr={tbGrids.grid40}>
        <Spacer spacer={Spacers.sLargeL}>
          {/* <InputText pslaceholder="Search Tibu" /> */}
        </Spacer>
      </Tgrid>
      <Tgrid gr={tbGrids.grid30}>
        <Spacer spacer={Spacers.sLargeL}>
          <CurrentUser />
        </Spacer>
      </Tgrid>
      <Tgrid gr={tbGrids.grid10}>
        <Spacer spacer={Spacers.sLargeL}>
          <Link to="/logout"><LogoutBtn /></Link>
        </Spacer>
      </Tgrid>
    </TopBar>
  );
}

export default Header;
