import React, { useState } from "react";
import styles from "./Select.module.scss";

const Select = props => {
  const {
    defaultText,
    value: defaultSelectedValue,
    options = [],
    onChange,
    ...rest
  } = props;

  const [value, setValue] = useState(defaultSelectedValue);

  function handleChange(e) {
    const newValue = e.target.value;

    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  }

  return (
    <div>
      <select
        {...rest}
        className={`${styles.tb_l_form__control}`}
        onChange={handleChange}
        value={value}
      >
        {(defaultText && (
          <option value="" key="default" hidden>
            {defaultText}
          </option>
        ))}

        {options.map((option, i) => (
          <option key={i} value={option.value || option.text}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
