import React from "react";
import Header from "../../../components/Header";
import ProfileBannerContainer from "../../../components/Profile/Banner/Container";
import { Query } from "react-apollo";
import { Type, TextBold, TextSize } from "../../../components/Type/Type";
import { Link } from "react-router-dom";
import Text from "../../../components/Type/Text";
import OrganizationSelect from "../../../components/Organization/Select/Container";
import Select from "../../../components/UI/Input/Test/Select";

import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../../components/Layout/Layout";
import {
  LIST_USERS,
  GET_ORGANIZATION,
  GET_LOCATION,
  GET_REGION
} from "../../../graphql";

const renderCaregivers = caregivers => {
  return caregivers.map((caregiver, idx) => (
    <Spacer key={idx}>
      <Link to={`profile/${caregiver.id}`}>
        {caregiver.firstName} {caregiver.lastName}{" "}
      </Link>{" "}
      {caregiver.email}
    </Spacer>
  ));
};
const renderManagers = managers => {
  return managers.map((caregiver, idx) => (
    <Spacer key={idx}>
      <Link to={`profile/${caregiver.id}`}>
        {caregiver.firstName} {caregiver.lastName}{" "}
      </Link>{" "}
      {caregiver.email}
    </Spacer>
  ));
};



/*
const UserList = ({ match }) => {
  const [organizationID, setOrganizationID] = useState("");

  return (
    <React.Fragment>
      <Header />
      <Rowz>
        <Wrapper>
          <Tgrid gr={tbGrids.grid20}>
            <Card>
              <Spacer>
                <ProfileBannerContainer />
              </Spacer>
            </Card>
          </Tgrid>
          <Tgrid gr={tbGrids.grid80}>
            <Spacer spacer={Spacers.sLargeL}>
              <Card>
                <Spacer>
                  <Rowz>
                    <Text>Organization:</Text>
                    <OrganizationSelect
                      onChange={orgID => setOrganizationID(orgID)}
                    />
                  </Rowz>
                </Spacer>
                {organizationID
                  ? renderUsersOfOrganization(organizationID)
                  : renderAllUsers()}
              </Card>
            </Spacer>
          </Tgrid>
        </Wrapper>
      </Rowz>
    </React.Fragment>
  );
};
*/

const RenderUsers = props => {
  return (
    <React.Fragment>
      <Spacer>
        <Type size={TextSize.lg} boldness={TextBold.bold}>
          Caregivers
        </Type>
      </Spacer>
      {renderCaregivers(props.caregivers)}
      <Spacer>
        <Type size={TextSize.lg} boldness={TextBold.bold}>
          Managers
        </Type>
      </Spacer>
      {renderManagers(props.managers)}
    </React.Fragment>
  );
};

class UserList extends React.Component {
  state = {
    managers: [],
    caregivers: [],
    organizationID: undefined,
    locationID: undefined,
    regionID: undefined
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  renderAllUsers = () => {
    return (
      <Query query={LIST_USERS}>
        {({ loading, error, data }) => {
          if (loading) return "loading...";
          if (error) return "error :(";

          if (data.listUsers) {
            const managers = data.listUsers.filter(
              user => user.role === "MANAGER"
            );
            const caregivers = data.listUsers.filter(
              user => user.role === "CAREGIVER"
            );

            return (
              <React.Fragment>
                <RenderUsers caregivers={caregivers} managers={managers} />
              </React.Fragment>
            );
          }
        }}
      </Query>
    );
  };

  renderUsersOfOrganization = organizationID => {
    return (
      <Query query={GET_ORGANIZATION} variables={{ id: organizationID }}>
        {({ loading, error, data }) => {
          if (loading) return "loading...";
          if (error) return "error :(";

          if (data.getOrganization) {
            const caregivers = data.getOrganization.caregivers;
            const managers = data.getOrganization.managers;

            return (
              <React.Fragment>
                <RenderUsers caregivers={caregivers} managers={managers} />
              </React.Fragment>
            );
          }
        }}
      </Query>
    );
  };

  renderUsersOfLocation = locationID => {
    return (
      <Query query={GET_LOCATION} variables={{ id: locationID }}>
        {({ loading, error, data }) => {
          if (loading) return "loading...";
          if (error) return "error :(";

          if (data.getLocation) {
            const caregivers = data.getLocation.caregivers;
            const managers = data.getLocation.managers;

            return (
              <React.Fragment>
                <RenderUsers caregivers={caregivers} managers={managers} />
              </React.Fragment>
            );
          }
        }}
      </Query>
    );
  };

  renderUsersOfRegion = regionID => {
    return (
      <Query query={GET_REGION} variables={{ id: regionID }}>
        {({ loading, error, data }) => {
          console.log("I have to render user for regions")
          if (loading) return "loading...";
          if (error) return "error :(";

          if (data.getRegion) {
            const caregivers = data.getRegion.caregivers;
            const managers = data.getRegion.managers;
           

            return (
              <React.Fragment>
                <RenderUsers caregivers={caregivers} managers={managers} />
              </React.Fragment>
            );
          }
        }}
      </Query>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <Rowz>
          <Wrapper>
            <Tgrid gr={tbGrids.grid20}>
              <Card>
                <Spacer>
                  <ProfileBannerContainer />
                </Spacer>
              </Card>
            </Tgrid>
            <Tgrid gr={tbGrids.grid80}>
              <Spacer spacer={Spacers.sLargeL}>
                <Card>
                  <Spacer>
                    
                    <Rowz>
                      <Spacer>
                        <Text>Organization:</Text>
                        <OrganizationSelect
                          onChange={orgID =>
                            this.handleInputChange("organizationID", orgID)
                          }
                        />
                      </Spacer>
                      {this.state.organizationID ? (
                      <Spacer>
                        <Query
                          query={GET_ORGANIZATION}
                          variables={{ id: this.state.organizationID }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return "loading...";
                            if (error) return "error :(";

                            if (data.getOrganization) {
                              const { regions } = data.getOrganization;
                              return (
                                <React.Fragment>
                                  <Text>Region:</Text>
                                  <Select
                                    required
                                    defaultText="--Select region--"
                                    onChange={value =>{
                                      this.setState({regionID:""})
                                      this.handleInputChange("regionID", value)
                                    }
                                      
                                    }
                                    options={regions.map(region => {
                                      return {
                                        text: region.name,
                                        value: region.id
                                      };
                                    })}
                                  />
                                </React.Fragment>
                              );
                            }
                          }}
                        </Query>
                      </Spacer>
                    ) : (
                      ""
                    )}
                     {this.state.organizationID ? (
                      <Spacer>
                        <Query
                          query={GET_ORGANIZATION}
                          variables={{ id: this.state.organizationID }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return "loading...";
                            if (error) return "error :(";

                            if (data.getOrganization) {
                              const { locations } = data.getOrganization;
                              return (
                                <React.Fragment>
                                  <Text>Location:</Text>
                                  <Select
                                    required
                                    defaultText="--Select location--"
                                    onChange={value =>{
                                      this.setState({regionID:""})
                                      this.handleInputChange("locationID", value)
                                    }
                                      
                                    }
                                    options={locations.map(location => {
                                      return {
                                        text: location.name,
                                        value: location.id
                                      };
                                    })}
                                  />
                                </React.Fragment>
                              );
                            }
                          }}
                        </Query>
                      </Spacer>
                    ) : (
                      ""
                    )}
                     
                    </Rowz>
                    {this.state.organizationID
                      ? (this.state.regionID
                        ? this.renderUsersOfRegion(this.state.regionID)
                        : (this.state.locationID
                          ? this.renderUsersOfLocation(this.state.locationID)
                          : this.renderUsersOfOrganization(this.state.organizationID)))
                      : this.renderAllUsers()}
                  </Spacer>
                </Card>
              </Spacer>
            </Tgrid>
          </Wrapper>
        </Rowz>
      </React.Fragment>
    );
  }
}
export default UserList;
