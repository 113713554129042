import React from "react";
// import InputText from "../../components/UI/Input/Text";
import Header from "../components/Header";
import { Type, TextBold, TextSize } from "../components/Type/Type";
import ProfileBannerContainer from "../components/Profile/Banner/Container"

import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids,
} from "../components/Layout/Layout";


const FormPage = (props) => (
  <React.Fragment>
            <Header/>
    <Rowz>
        <Wrapper>
          <Tgrid gr={tbGrids.grid20}>
            <Card>
              <Spacer>
                
                  <ProfileBannerContainer />
                
              </Spacer>
            </Card>
          </Tgrid>
          <Tgrid gr={tbGrids.grid80}>
            <Spacer spacer={Spacers.sLargeL}>
              <Card>
                <Spacer>
                <Type size={TextSize.lg} boldness={TextBold.bold}>
                  {props.title}
                </Type>
                </Spacer>
                {props.form}
              </Card>
            </Spacer>
          </Tgrid>
        </Wrapper>
      </Rowz>
                           
            
  </React.Fragment>
)

export default FormPage;
