import React from "react";
import LogoSVG from "./logo-purple.svg";
import { logo } from "./Logo.module.scss";

function Logo(props){
  const {
    portalName = "Tibu portal"
  } = props;

  return (
    <div className={logo}>
      <img src={LogoSVG} alt="Logo" />
      <span>{portalName}</span>
    </div>
  )
}

export default Logo;
