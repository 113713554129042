import React from "react";
import "./Profile.css";
import {
  Card,
  Spacer,
} from "../../Layout/Layout";

import ProfileBrief from "../../UI/Profile/Brief";

import { Type, TextBold, TextSize } from "../../Type/Type";

const Profile = ({ region }) => {
  const listLeft = [];
  const listRight = [];

  console.log("region: " + JSON.stringify(region,null,2))
  listLeft.push({ name: "Name", value: region.name });
  listLeft.push({ name: "Reference number", value: region.refNumber });

  listRight.push({ name: "Organization", value: region.organization.name });
  listRight.push({ name: "Place", value: region.place });

  return (
    <Spacer>
      <Card>
        <Spacer>
          <Spacer>
          <Type size={TextSize.lg} boldness={TextBold.bold}>
            {region.name}
          </Type>
          </Spacer>          
          <ProfileBrief listLeft={listLeft} listRight={listRight} />
        </Spacer>
      </Card>
    </Spacer>
  );
};

export default Profile;
