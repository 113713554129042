import React from "react";
import Header from "../../components/Header";
import OrganizationProfileContainer from "../../components/Organization/Profile/Container";

const Organization = ({ match }) => (
  <React.Fragment>
    <Header />
    <div>
      Organization page
      <OrganizationProfileContainer organizationID={match.params.id} />
    </div>
  </React.Fragment>
);

export default Organization;