import React from "react";
import FormPage from "./FormPage"
import AssignCaregiverToJobForm from "../components/AssignCaregiverToJobForm/AssignCaregiverToJobForm"

const CaregiverToJobAssignment = () => (
    <FormPage title="Assign caregiver to job" form={<AssignCaregiverToJobForm/>} />
)

export default CaregiverToJobAssignment;

