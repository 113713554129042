import React from "react";
import Text from "../../Type/Text/Text";

import {
    Spacers,
    Tgrid,
    tbGrids,
    Fonts
  } from "../../Layout/Layout";

const months = {
    1 : "Jan",
    2 : "Feb",
    3 : "Mar",
    4 : "May",
    5 : "Jun",
    6 : "Jul",
    7 : "Aug",
    8 : "Sep",
    9 : "Sep",
    10 : "Oct",
    11 : "Nov",
    12 : "Dic"

}




function Studies({studiesEntries}){

    return(
        <React.Fragment>       
        
        {studiesEntries.map(({id,startMonth, startYear, endMonth, endYear,school, studies, present}) => (
            <React.Fragment key={id}>
                <Tgrid gr={tbGrids.grid20} spacer={Spacers.sBLarge}>
                <Text spacer={Spacers.sBMedium} font={Fonts.brand}>
                {startMonth ? months[startMonth] : ""} {startYear} - {endMonth ? months[endMonth] : ""} {endYear ? endYear : "present"}
                </Text>
            </Tgrid>
            <Tgrid gr={tbGrids.grid80} spacer={Spacers.sBLarge}>
                <Text spacer={Spacers.sBMedium}>
                {school ? `${school} -` : ""}  {studies}
                </Text>
            </Tgrid>
            </React.Fragment>
            
        ))}      
        
        </React.Fragment>
             
    )
}

export default Studies;