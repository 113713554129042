import React from "react";
import { Query } from "react-apollo";
import { LIST_USERS } from "../../../graphql";
import InputSelect from "../../UI/Input/Select";

const Container = ({onChange,filterRole,...rest}) => (
  <Query query={LIST_USERS}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;
     let users = data.listUsers

     if(filterRole){
          users = users.filter(user => user.role === filterRole)
       
     }
      return (
        <InputSelect
        options={users.map(user =>{
          return(
            {text: `${user.firstName} ${user.lastName}`,value:user.id}
          )
        }
        ).filter(c => c)}
        onChange={value => onChange(value)}
        defaultText="-- Select user --"
        {...rest}
        />
        
      )
    }}
  </Query>
);

export default Container;
