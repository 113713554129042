import React from "react";
import {
  Rowz,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids,
} from "../../../Layout/Layout";
import styles from "./ProfileBrief.module.scss";

function ProfileBrief({listLeft=[], listRight=[],onClick}) {
  return (
    <Rowz>
      <Tgrid gr={tbGrids.grid50}>
        <Spacer spacer={Spacers.sLargeR}>
          <ul
            className={`${styles.tb_l_quick_summary} ${styles.tb_l_user_prop}`}
          >
            {listLeft.map(item => (
              <li key={item.name}>
                <span className={`${styles.tb_l_user_prop__head}`}>
                  {item.name}
                </span>
                <span className={`${styles.tb_l_quick_summary__status}`}>
                  {item.value}
                </span>
              </li>
            ))}
          </ul>
        </Spacer>
      </Tgrid>
      <Tgrid gr={tbGrids.grid50}>
        <Spacer spacer={Spacers.sLargeR}>
          <ul
            className={`${styles.tb_l_quick_summary} ${styles.tb_l_user_prop}`}
          >
            {listRight.map(item => (
              <li key={item.name}>
                <span className={`${styles.tb_l_user_prop__head}`}>
                  {item.name}
                </span>
                <span className={`${styles.tb_l_quick_summary__status}`}>
                  {item.value}
                </span>
              </li>
            ))}
          </ul>
        </Spacer>
      </Tgrid>
    </Rowz>
  );
}

export default ProfileBrief;
