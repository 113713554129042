import React, { useState } from "react";
import { Query, withApollo } from "react-apollo";
import { Card, Spacer, Spacers, Tgrid, tbGrids } from "../../../components/Layout/Layout";
import Text from "../../../components/UI/Input/Text";
import Button from "../../../components/UI/Button";
import styles from "./Summary.module.scss";
import { GET_TIME_LOGS, EXPORT_TIME_LOGS } from "../TimeOperations";
import moment from "moment";

function Timesummary(props) {
  const { startDate, setStartDate, endDate, setEndDate, client } = props;
  const [exporting, setExporting] = useState(false);

  const firstDate = "2019-01-01";
  const today = moment().format("YYYY-MM-DD");

  function exportTimeLogs() {
    client
      .mutate({
        mutation: EXPORT_TIME_LOGS,
        variables: { startDate, endDate }
      })
      .then(() => {
        setExporting(false);
      });
      
    setExporting(true);
  }

  function renderFilteredDates() {
    if (!startDate && !endDate) {
      return "All dates";
    }

    const prettyStartDate = moment(startDate).format("MMM Do, YYYY");
    const prettyEndDate = moment(endDate).format("MMM Do, YYYY");

    const startDateText = startDate ? `From ${prettyStartDate}` : "";
    const endDateText = endDate
      ? startDate
        ? ` to ${prettyEndDate}`
        : `Up to ${prettyEndDate}`
      : "";
    return `${startDateText}${endDateText}`;
  }

  let parsedStartDate;
  if (startDate) {
    parsedStartDate = new Date(startDate);
    parsedStartDate.setHours(0);
    parsedStartDate.setMinutes(0);
    parsedStartDate.setSeconds(0);
  }

  let parsedEndDate;
  if (endDate) {
    parsedEndDate = new Date(endDate);
    parsedEndDate.setHours(0);
    parsedEndDate.setMinutes(0);
    parsedEndDate.setSeconds(0);
  }

  return (
    <div className={`${styles.tb_l_as_summary}`}>
      <Tgrid gr={tbGrids.grid50}>
        <Spacer spacer={Spacers.sSmallR}>
          <Card>
            <Spacer>
              <span
                className={`${styles.tb_l_as_summary__header} ${
                  styles.tb_l_spacer_b__medium
                }`}
              >
                Filter dates
              </span>
              <Tgrid gr={tbGrids.grid40} bottom={Spacers.resetb}>
                <Spacer spacer={Spacers.sSmallR}>
                  <Text
                    date
                    min={firstDate}
                    max={endDate ? endDate : today}
                    value={startDate}
                    onChange={setStartDate}
                  />
                </Spacer>
              </Tgrid>
              <Tgrid gr={tbGrids.grid40} bottom={Spacers.resetb}>
                <Spacer spacer={Spacers.sSmallR}>
                  <Text
                    date
                    min={startDate ? startDate : firstDate}
                    max={today}
                    value={endDate}
                    onChange={setEndDate}
                  />
                </Spacer>
              </Tgrid>
              <Tgrid gr={tbGrids.grid20} bottom={Spacers.resetb}>
                <Spacer spacer={Spacers.sSmallL}>
                  <Button onClick={exportTimeLogs} disabled={exporting}>{exporting ? "Exporting..." : "Export"}</Button>
                </Spacer>
              </Tgrid>
            </Spacer>
          </Card>
        </Spacer>
      </Tgrid>
      <Tgrid gr={tbGrids.grid50}>
        <Spacer spacer={Spacers.sSmallL}>
          <Card>
            <Query
              query={GET_TIME_LOGS}
              variables={{
                startDate: parsedStartDate && parsedStartDate.getTime(),
                endDate: parsedEndDate && parsedEndDate.getTime()
              }}
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data }) => {
                if (loading) return <p>loading...</p>;
                if (error) return <p>Error :(</p>;

                const timeLogs = data.listTimeLogEntries || [];

                let totalApproved = 0;
                let totalPending = 0;
                timeLogs.forEach((timeLog) => {
                  const duration = moment(timeLog.endTime) - moment(timeLog.startTime);
                  if(timeLog.status === "APPROVED") {
                    totalApproved += duration;
                  } else if(timeLog.status === "PENDING") {
                    totalPending += duration;
                  }
                });

                function renderDuration(seconds) {
                  return `${Math.floor(seconds / 3600).toString().padStart(2, '0')}:${Math.floor((seconds % 3600) / 60).toString().padStart(2, '0')}`;
                }

                return (
                  <Spacer>
                    <span
                      className={`${styles.tb_l_as_summary__header} ${
                        styles.tb_l_spacer_b__medium
                      }`}
                    >
                      {renderFilteredDates()}
                    </span>
                    <Tgrid gr={tbGrids.grid50} bottom={Spacers.resetb}>
                      <Spacer spacer={Spacers.sSmallR}>
                        <span className={`${styles.tb_l_big_time}`} />
                        <span className={`${styles.tb_l_small_text}`}>
                          <span className={`${styles.tb_l_bolder}`}>
                            {renderDuration(totalApproved)}
                          </span>
                          <span>Approved hours</span>
                        </span>
                      </Spacer>
                    </Tgrid>
                    <Tgrid gr={tbGrids.grid50} bottom={Spacers.resetb}>
                      <Spacer spacer={Spacers.sSmallR}>
                        <span
                          className={`${styles.tb_l_big_time} ${
                            styles.tb_l_big_time__error
                          }`}
                        />
                        <span className={`${styles.tb_l_small_text}`}>
                          <span className={`${styles.tb_l_bolder}`}>
                            {renderDuration(totalPending)}
                          </span>
                          <span>Pending hours</span>
                        </span>
                      </Spacer>
                    </Tgrid>
                  </Spacer>
                );
              }}
            </Query>
          </Card>
        </Spacer>
      </Tgrid>
    </div>
  );
}

export default withApollo(Timesummary);
