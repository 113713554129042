import React from "react";
import { Mutation } from "react-apollo";
import { UPDATE_PATIENT } from "../../../graphql";
import InputText from "../../UI/Input/Text";
import Text from "../../Type/Text";
import Button from "../../UI/Button";
import { Rowz, Spacer} from "../../Layout/Layout";

class PatientUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    const {
      firstName,
      lastName,            
      refNumber
    } = props.patientData;



    this.state = {        
      firstName,
      lastName,
      regionID: props.regionID,      
      refNumber
    };

  }

  handleInputChange = (name, value) => {
    
      this.setState({
        [name]: value
      });
    
  };

  render() {    
    return (
      <Mutation mutation={UPDATE_PATIENT}>
        {(updatePatient, { data, called, error, loading }) => {
          if (called && !error && !loading) {
            window.location.reload();
          }
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                updatePatient({ variables: { id: this.props.patientID, input: this.state } });
              }}
            >
              <Spacer>
              <Spacer>
                    <Rowz>
                      <Text>First name:</Text>
                      <InputText
                        name="firstName"
                        placeholder="Luuk"
                        value={this.state.firstName || undefined}
                        required
                        onChange={value =>
                          this.handleInputChange("firstName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Last name:</Text>
                      <InputText
                        name="lastName"
                        placeholder="Akkerman"
                        value={this.state.lastName || undefined}
                        required
                        onChange={value =>
                          this.handleInputChange("lastName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                  <Rowz>
                      <Text>Reference number:</Text>
                      <InputText
                        name="refNumber"
                        placeholder="12345"
                        value={this.state.refNumber || undefined}
                        required
                        onChange={value =>
                          this.handleInputChange("refNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>             

                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Update
                    </Button>
                  </Spacer>
              </Spacer>
            </form>
          );
        }}
      </Mutation>
    );
  }
}

export default PatientUpdateForm;
