import React from "react";
import { Query } from "react-apollo";
import { LIST_ORGANIZATIONS } from "../../../graphql";
import InputSelect from "../../UI/Input/Select";


const Container = ({onChange,type,...rest}) => (
  <Query query={LIST_ORGANIZATIONS} variables={{type}}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;
     const organizations = data.listOrganizations
      return (
        <InputSelect
        options={organizations.map(organization =>{
          return(
            {text: organization.name,value:organization.id}
          )
        }
        ).filter(c => c)}
        onChange={value => onChange(value)}
        defaultText="-- Select organization --"
        {...rest}
        />
        
      )
    }}
  </Query>
);

export default Container;
