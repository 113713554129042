import {Mutation} from "react-apollo"
import React from "react"
import gql from "graphql-tag"
import Button from "../UI/Button"



const Logout= () => {
    return(
        
            <Mutation mutation={gql`mutation logOut{ logOut }`}>
              {logOut => {
                return (
                  <Button onClick={() => {
                    logOut().then(() => {
                      localStorage.removeItem("t-rt");
                      localStorage.removeItem("t-at");
                      window.location.pathname = "/";
                    })
                  }}>Logout</Button>
                )
              }}
            </Mutation>
          
    )
}

export default Logout;