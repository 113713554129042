import React from "react";
import { Query } from "react-apollo";
import { GET_USER } from "../../../graphql";
import ProfileBrief from "../../UI/Profile/Brief";
import moment from "moment"

function getAgeFromBirthdate(birthdate){
  
  const today = moment()
  const birthday = moment(birthdate*1000)

  const duration = moment.duration(today.diff(birthday))
  const age = Math.trunc(duration.asYears())

  return age;
}
/**
 * 
 * @param {Object} userData User data fetched from the backend
 * @returns List of objects to be inserted in profile brief left side
 */
function createBriefLeftList(userData){

  
  const briefLeftList = [];

  let platformStartDate

  if(userData.platformStartDate){
    platformStartDate = moment(userData.platformStartDate*1000).format("DD/MM/YYYY")
  }else{
    platformStartDate = "Not set"
  }
  
  briefLeftList.push({name: "First name", value: userData.firstName});
  briefLeftList.push({name: "Last name", value: userData.lastName});
  briefLeftList.push({name: "Email", value: userData.email});
  briefLeftList.push({name: "Address", value: userData.address});
  briefLeftList.push({name: "Postal code", value: userData.postalCode});
  briefLeftList.push({name: "Platform", value: userData.platform});
  briefLeftList.push({name: "Platform start date", value: platformStartDate});
  briefLeftList.push({name: "Platform end date", value: userData.platformEndDate || "Not finished"});
  
    
  return briefLeftList
}

/**
 * 
 * @param {Object} userData User data fetched from the backend
 * @returns List of objects to be inserted in profile brief left side
 */
function createBriefRightList(userData){
  const briefRightList = [];
  
  briefRightList.push({name: "Living place", value: userData.livingPlace});
  briefRightList.push({name: "Age", value: getAgeFromBirthdate(userData.birthdate)});
  briefRightList.push({name: "Gender", value: userData.gender});
  briefRightList.push({name: "Phone number", value: userData.phoneNumber});
  briefRightList.push({name: "Owns Car", value: userData.ownsCar ? "Yes" : "No"});
  briefRightList.push({name: "Driving license", value: userData.drivingLicense ? "Yes" : "No"});
  
  return briefRightList
  
}

const Container = ({userID,onClick,...rest}) => (
  <Query query={GET_USER} variables={{userID}}>
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;

      if (data.getUser) {
        const listLeft = createBriefLeftList(data.getUser);
        const listRight = createBriefRightList(data.getUser);
				return <ProfileBrief listLeft={listLeft} listRight={listRight} onClick={onClick} {...rest} />;
				
      }
    }}
  </Query>
);

export default Container;
