import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { CREATE_ORGANIZATION } from "../../../graphql";
import InputText from "../../UI/Input/Text";
import InputSelect from "../../UI/Input/Select";
import Text from "../../Type/Text";
import Button from "../../UI/Button";

import {
  Rowz,
  Spacer
} from "../../../components/Layout/Layout";

class OrganizationRegistrationForm extends React.Component {
  state = {
    name: "",
    type: "PLATFORM",
    address: "",
    postalCode: "",
    place: "",
    phoneNumber: "",
    refNumber: "",
    email: ""
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <Mutation mutation={CREATE_ORGANIZATION}>
        {(createOrganization, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.createOrganization) {
              return <Redirect to={{
                pathname: "/",
                state : {message : "Organization successfully created"}
              }} />;
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createOrganization({ variables: { input: this.state } });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Name:</Text>
                      <InputText
                        name="name"
                        placeholder="De Linde"
                        required
                        onChange={value =>
                          this.handleInputChange("name", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Type:</Text>
                      <InputSelect
                        name="type"
                        required
                        options={[
                          { value: "PLATFORM", text: "Platform" },
                          { value: "HEALTHCARE", text: "Healthcare" },
                        ]}
                        onChange={value =>
                          this.handleInputChange("type", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Address:</Text>
                      <InputText
                        name="address"
                        placeholder="Gedempte Oude Gracht"
                        required
                        onChange={value =>
                          this.handleInputChange("address", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Place:</Text>
                      <InputText
                        name="place"
                        placeholder="Zuid"
                        required
                        onChange={value =>
                          this.handleInputChange("place", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  

                  <Spacer>
                    <Rowz>
                      <Text>Postal Code:</Text>
                      <InputText
                        name="postalCode"
                        placeholder="2011GM"
                        required
                        onChange={value =>
                          this.handleInputChange("postalCode", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  
                  <Spacer>
                    <Rowz>
                      <Text>Phone number:</Text>
                      <InputText
                        name="phoneNumber"
                        placeholder="0235575174"
                        required
                        onChange={value =>
                          this.handleInputChange("phoneNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Ref Number:</Text>
                      <InputText
                        name="refNumber"
                        placeholder="124315135"
                        required
                        onChange={value =>
                          this.handleInputChange("refNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Email:</Text>
                      <InputText
                        name="email"
                        placeholder="walter@sdl.nl"
                        required
                        onChange={value =>
                          this.handleInputChange("email", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer >
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default OrganizationRegistrationForm;
