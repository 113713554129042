import React from "react";
import "./Profile.css";
import {
  Card,
  Spacer,
} from "../../Layout/Layout";

import ProfileBrief from "../../UI/Profile/Brief";

import { Type, TextBold, TextSize } from "../../Type/Type";

const Profile = ({ organization }) => {
  const listLeft = [];
  const listRight = [];

  listLeft.push({ name: "Ref Number", value: organization.refNumber });
  listLeft.push({ name: "Address", value: organization.address });
  listLeft.push({ name: "Postal Code", value: organization.postalCode });

  listRight.push({ name: "Place", value: organization.place });
  listRight.push({ name: "Email", value: organization.email });
  listRight.push({ name: "Phone number", value: organization.phoneNumber });

  return (
    <Spacer>
      <Card>
        <Spacer>
          <Spacer>
          <Type size={TextSize.lg} boldness={TextBold.bold}>
            {organization.name}
          </Type>
          </Spacer>          
          <ProfileBrief listLeft={listLeft} listRight={listRight} />
        </Spacer>
      </Card>
    </Spacer>
  );
};

export default Profile;
