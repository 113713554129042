import React from "react";
import { Query } from "react-apollo";
import { CURRENT_USER } from "../../graphql";
import Tavatar from "../UI/Tavatar"
import { Floaters } from "../Layout/Layout"

const Container = () => (
  <Query query={CURRENT_USER} >
    {({ loading, error, data }) => {
      if(loading) return <p>Loading...</p>;
      if(error) return <p>Error :(</p>;

      return <Tavatar firstName={data.currentUser.firstName} lastName={data.currentUser.lastName} float={Floaters.right} />;
      // return <p>AVATAR COMPONENT MISSING</p>;
    }}
  </Query>
);

export default Container;