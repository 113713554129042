import React from "react";
import LocationUpdateForm from "../../components/Location/UpdateForm/Container";
import RegionUpdateForm from "../../components/Region/UpdateForm/Container";
import PatientUpdateForm from "../../components/Patient/UpdateForm/Container";
import OrganizationUpdateForm from "../../components/Organization/UpdateForm/Container";
import Header from "../../components/Header";
import { Type, TextBold, TextSize } from "../../components/Type/Type";
import ProfileBannerContainer from "../../components/Profile/Banner/Container";
import { useState } from "react";
import LocationSelect from "../../components/Location/Select/Container"
import RegionSelect from "../../components/Region/Select/Container"
import OrganizationSelect from "../../components/Organization/Select/Container"
import PatientSelect from "../../components/Patient/Select/Container"

import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../components/Layout/Layout";

const UpdatePanel = () => {

  const [locationID, setLocationID] = useState("");
  const [regionID, setRegionID] = useState("");
  const [organizationID, setOrganizationID] = useState("");
  const [patientID, setPatientID] = useState("");


  return (
    <React.Fragment>
      <Header />
      <Rowz>
        <Wrapper>
          <Tgrid gr={tbGrids.grid20}>
            <Card>
              <Spacer>
                <ProfileBannerContainer />
              </Spacer>
            </Card>
          </Tgrid>

          <Tgrid gr={tbGrids.grid80}>
            <Spacer spacer={Spacers.sLargeL}>
              <Card>
                <Spacer>
                  <Type size={TextSize.lg} boldness={TextBold.bold}>
                    Update panel
                  </Type>
                </Spacer>
                <Spacer>
                  <Type size={TextSize.lg} boldness={TextBold.bold}>
                    Location
                  </Type>
                  <LocationSelect onChange={value => setLocationID(value)}/>
                  {locationID ? <LocationUpdateForm locationID={locationID} /> : ""}
                </Spacer>
                <Spacer>
                  <Type size={TextSize.lg} boldness={TextBold.bold}>
                    Region
                  </Type>
                  <RegionSelect onChange={value => setRegionID(value)}/>
                  {regionID ? <RegionUpdateForm regionID={regionID} /> : ""}
                </Spacer>
                <Spacer>
                  <Type size={TextSize.lg} boldness={TextBold.bold}>
                    Organization
                  </Type>
                  <OrganizationSelect onChange={value => setOrganizationID(value)}/>
                  {organizationID ? <OrganizationUpdateForm organizationID={organizationID} /> : ""}
                </Spacer>
                <Spacer>
                  <Type size={TextSize.lg} boldness={TextBold.bold}>
                    Patient
                  </Type>
                  <PatientSelect onChange={value => setPatientID(value)}/>
                  {patientID ? <PatientUpdateForm patientID={patientID} /> : ""}
                </Spacer>
              </Card>
            </Spacer>
          </Tgrid>
        </Wrapper>
      </Rowz>
    </React.Fragment>
  );
};

export default UpdatePanel;
