import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Route from "./components/Route";
import Login from './views/Login';
import Logout from './views/Logout';
import Home from './views/Home/Home';
import NotFound from './views/NotFound';
import Organization from './views/Organization/Organization';
import OrganizationAssignManager from './views/Organization/AssignManager';
import OrganizationAssignUser from './views/Organization/AssignUser';
import Location from './views/Location/Location';
import Region from './views/Region/Region';
import RegionRegistration from './views/Region/Registration';
import RegionAssignManager from './views/Region/AssignManager';
import LocationRegistration from './views/Location/Registration';
import LocationAssignManager from './views/Location/AssignManager';
import OrganizationRegistration from './views/Organization/Registration';
import UserClientRegistration from './views/User/ClientRegistration';
import PatientRegistration from './views/Patient/Registration';
import CaregiverRegistration from './views/User/CaregiverRegistration';
import ManagerRegistration from './views/User/ManagerRegistration';
import Profile from './views/Profile/Profile';
import OrganizationList from './views/Organization/List/OrganizationList';
import LocationList from './views/Location/List/LocationList';
import RegionList from './views/Region/List/RegionList';
import PatientList from './views/Patient/List/PatientList';
import Timesheet from './views/Timesheet';
import UserList from './views/User/List/UserList';
import DeletePanel from './views/DeletePanel/DeletePanel';
import UpdatePanel from './views/UpdatePanel/UpdatePanel';
import JobRegistration from './views/JobRegistration'
import CaregiverToJobAssignment from './views/CaregiverToJobAssignment'
import CaregiverToJobRemove from './views/CaregiverToJobRemove'
import Shell from './Shell';
import './App.css';

const App = () => (
	<Router>
		<Shell>
			<Switch>
        <Route exact path="/login" component={Login} />
        <Route secure exact path="/logout" component={Logout} />
				<Route secure path="/" exact component={Home} />
				<Route secure path="/organization/register" exact component={OrganizationRegistration} />
				<Route secure path="/organization/assignManager" exact component={OrganizationAssignManager} />
				<Route secure path="/organization/assignUser" exact component={OrganizationAssignUser} />
				<Route secure path="/location/register" exact component={LocationRegistration} />
				<Route secure path="/location/assignManager" exact component={LocationAssignManager} />
				<Route secure path="/caregiver/register" exact component={CaregiverRegistration} />
				<Route secure path="/manager/register" exact component={ManagerRegistration} />
				<Route secure path="/region/register" exact component={RegionRegistration} />
				<Route secure path="/region/assignManager" exact component={RegionAssignManager} />
				<Route secure path="/patient/register" exact component={PatientRegistration} />
				<Route secure path="/organization/:id" component={Organization} />
				<Route secure path="/location/:id" component={Location} />
				<Route secure path="/region/:id" component={Region} />
				<Route secure path="/profile/:userID" component={Profile} />
				<Route secure path="/profile" component={Profile} />
				<Route secure path="/organizations" component={OrganizationList} />
				<Route secure path="/locations" component={LocationList} />
				<Route secure path="/regions" component={RegionList} />
				<Route secure path="/timesheet" component={Timesheet} />
				<Route secure path="/users" component={UserList} />
				<Route secure path="/patients" component={PatientList} />
				<Route secure path="/user/client-register" component={UserClientRegistration} />
				<Route secure path="/delete-panel" component={DeletePanel} />
				<Route secure path="/update-panel" component={UpdatePanel} />
				<Route secure path="/job/register" component={JobRegistration} />
				<Route secure path="/job/assign-caregiver" component={CaregiverToJobAssignment} />
				<Route secure path="/job/remove-caregiver" component={CaregiverToJobRemove} />
				<Route secure component={NotFound} />
			</Switch>
		</Shell>
	</Router>
);

export default App;
