import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { SIGN_UP_MANAGER } from "../../../graphql";
import Text from "../../Type/Text";
import Button from "../../UI/Button";
import InputText from "../../UI/Input/Text"
import { Rowz, Spacer } from "../../Layout/Layout";
import OrganizationSelect from "../../Organization/Select/Container"

class ManagerRegistrationForm extends React.Component {
  state = {
      email: "",
      password: "",
      passwordError: "", 
      firstName: "",
      lastName: "",
      phoneNumber: "",
      organizationID: undefined,
      position: "",
      role: "MANAGER"      
  };

  verifyPassword = (password)=>{
    

    if(password.length < 6){
      this.setState({passwordError : "Password must be at least 6 characters long"})
      return false;
    }

    if (!/[A-Z]+/.test(password)) {
      this.setState({passwordError: "Password must include one uppercase character"})
      return false;
    }

    if (!/[0-9]+/.test(password)) {
      this.setState({passwordError: "Password must include one numeric character"})
      return false;
    }

    if (!/[^0-9A-Za-z]+/.test(password)) {
      this.setState({passwordError: "Password must include one special character"})
      return false;
    }

    this.setState({passwordError: ""})

    return true;
  }

  handleInputChange = (name, value) => {
    if(name === "password"){
      if(this.verifyPassword(value)){
        this.setState({
          [name]: value
        });
      }
    }else{
      this.setState({
        [name]: value
      });
    }
  };
  
  render() {
    return (
      <Mutation mutation={SIGN_UP_MANAGER}>
        {(signUpManager, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.signUpManager) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `New manager registered succesfully`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();

                  // Input receives all the properties of state except passwordError
                  const {passwordError, ...input} = this.state

                  signUpManager({ variables: { input } });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>First name:</Text>
                      <InputText
                        name="firstName"
                        placeholder="Luuk"
                        required
                        onChange={value =>
                          this.handleInputChange("firstName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Last name:</Text>
                      <InputText
                        name="lastName"
                        placeholder="Akkerman"
                        required
                        onChange={value =>
                          this.handleInputChange("lastName", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  
                  <Spacer>
                    <Rowz>
                      <Text>Email:</Text>
                      <InputText
                        name="email"
                        placeholder="user@tibu.nu"
                        required
                        onChange={value =>
                          this.handleInputChange("email", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <div>{this.state.passwordError}</div>
                      <Text>Password:</Text>
                      <InputText
                        name="password"                    
                        required
                        password
                        title=" Password length must be greater than 8 &#013; Password must contain a capital letter &#013; Password must contain a number &#013; Password must contain a special character "
                        onChange={value =>
                          this.handleInputChange("password", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                                    
                  <Spacer>
                    <Rowz>
                      <Text>Phone number:</Text>
                      <InputText
                        name="phoneNumber"
                        onChange={value =>
                          this.handleInputChange("phoneNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>

                  <Spacer>
                    <Rowz>
                      <Text>Organization:</Text>
                      <OrganizationSelect required onChange={value =>
                          this.handleInputChange("organizationID", value)}/>
                    </Rowz>
                    </Spacer>		

                    <Spacer>
                    <Rowz>
                      <Text>Position:</Text>
                      <InputText
                        name="position"
                        onChange={value =>
                          this.handleInputChange("position", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  
                  <Spacer>
                    <Button type="submit" disabled={loading || this.state.passwordError}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default ManagerRegistrationForm;
