import React from "react";
import { Query } from "react-apollo";
import { GET_LOCATION } from "../../../graphql";
import Profile from "./Profile";

const Container = ({ locationID }) => (
  <Query query={GET_LOCATION} variables={{ id: locationID }}>
    {({ loading, error, data }) => {
      console.log("Hello")
      if(loading) return <p>Loading...</p>;
      if(error) return <p>Error :(</p>;
      
      
      console.log("data:" + JSON.stringify(data,null,2))
      return <Profile location={data.getLocation} />;
    }}
  </Query>
);

export default Container;