import React from "react"
import EditIcon from "./Edit-tb-ico.svg"
import LocationIcon from "./Navigation-tb-ico.svg"
import MailIcon from "./Mail-tb-ico.svg"
import ShareIcon from "./Share-tb-ico.svg"
import LanguageIcon from "./Lang-tb-ico.svg"
import { body, icon, img, item} from "./Entry.module.scss";

const Entry = props => {
    let src=EditIcon;
    switch(props.icon){
        case "Location":
            src = LocationIcon;
            break
        case "Edit":
            src = EditIcon
            break
        case "Mail":
            src = MailIcon
            break
        case "Share":
            src = ShareIcon
            break
        case "Language":
            src = LanguageIcon
            break
        default:
            src = EditIcon

    }

    return(
        <React.Fragment>
            <li className={item}>
                <span className={icon}>
                    <img className={img} src={src} alt=""/>
                </span>
                <div className={body}>
                    {props.children}
                </div>
            </li>
        </React.Fragment>
        
    )
}

export default Entry