import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import { DELETE_LOCATION } from "../../../graphql";
import Button from "../../UI/Button";
import LocationSelect from "../../Location/Select/Container"

import {
  Spacer,
  Tgrid,
  tbGrids
} from "../../../components/Layout/Layout";

class LocationDeleteForm extends React.Component {
  state = {
    locationID: ""
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  
  render() {
    return (
      <Mutation mutation={DELETE_LOCATION}>
        {(deleteLocation, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.deleteLocation) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Location ${
                        data.deleteLocation.name
                      } successfully deleted`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
                <form
                onSubmit={e => {
                  e.preventDefault();
                  deleteLocation({ variables: { id: this.state.locationID } });
                }}
              >
                <Spacer>
                  
                  <Tgrid gr={tbGrids.grid70}>
                  <Spacer>
                      <LocationSelect required onChange={value=>this.handleInputChange("locationID",value)}/>
                    </Spacer>
                  </Tgrid>
                  <Tgrid gr={tbGrids.grid30}>
                  <Spacer><Button type="submit" disabled={loading}>
                      Delete
                    </Button></Spacer>

                  
                  </Tgrid>
                  
                </Spacer>
              </form>
              
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default LocationDeleteForm;