import React from "react";
import { Query } from "react-apollo";
import { GET_REGION } from "../../../graphql";
import UpdateForm from "./UpdateForm"

// Fetches current user data to be pass as default values for the update user form
const Container = ({regionID}) => (
    <Query query={GET_REGION} variables={{id: regionID}}>{
        ({data,error,loading})=>{
            if(error)return "Error :("
            if(loading) return "Loading ..."

            if(data.getRegion){
                return <UpdateForm regionID={regionID} regionData={data.getRegion} organizationID={data.getRegion.organization.id}/>
            }
        }
        
    }
    </Query>

)

export default Container