import React, { useState } from "react";
import moment from "moment";
import Header from "../../components/Header";
import ProfileBannerContainer from "../../components/Profile/Banner/Container";
import Summary from "./Summary";
import TableContainer from "./Table/Container";

import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../components/Layout/Layout";

function Timesheet() {
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  return (
    <React.Fragment>
      <Header />
      <Rowz>
        <Wrapper>
          <Tgrid gr={tbGrids.grid20}>
            <Card>
              <Spacer>
                <ProfileBannerContainer />
              </Spacer>
            </Card>
          </Tgrid>
          <Tgrid gr={tbGrids.grid80}>
            <Spacer spacer={Spacers.sLargeL}>
              <Card>
                <Spacer>
                  <Rowz>
                    <Summary {...{startDate, setStartDate, endDate, setEndDate}} />
                    <TableContainer {...{startDate, endDate}} />
                  </Rowz>
                </Spacer>
              </Card>
            </Spacer>
          </Tgrid>
        </Wrapper>
      </Rowz>
    </React.Fragment>
  );
}

export default Timesheet;
