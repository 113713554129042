import React from "react";
import OrganizationProfile from "../../../components/Organization/Profile/Profile";
import Header from "../../../components/Header";
import ProfileBannerContainer from "../../../components/Profile/Banner/Container";
import { Query } from "react-apollo";
import { Type, TextBold, TextSize } from "../../../components/Type/Type";


import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids
} from "../../../components/Layout/Layout";
import { LIST_ORGANIZATIONS } from "../../../graphql";

const OrganizationList = ({ match }) => (
  <React.Fragment>
    <Header/>
    <Rowz>
      <Wrapper>
        <Tgrid gr={tbGrids.grid20}>
          <Card>
            <Spacer>
              <ProfileBannerContainer />
            </Spacer>
          </Card>
        </Tgrid>
        <Tgrid gr={tbGrids.grid80}>
        <Spacer spacer={Spacers.sLargeL}>
              <Card>
                <Spacer>
                <Type size={TextSize.lg} boldness={TextBold.bold}>
                  Organizations
                </Type>
                </Spacer>
              
                <Query query={LIST_ORGANIZATIONS}>
            {({ loading, error, data }) => {
              if (loading) return "loading...";
              if (error) return "error :(";

              if (data.listOrganizations) {
                return data.listOrganizations.map((organization, idx) => (
                  <OrganizationProfile key={idx} organization={organization} />
                ));
              }
            }}
          </Query>
              
              </Card>
            </Spacer>
          
          
        </Tgrid>
      </Wrapper>
    </Rowz>
  </React.Fragment>
);

export default OrganizationList;
