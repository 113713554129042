import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import Text from "../Type/Text";
import Button from "../UI/Button";
import gql from "graphql-tag";
import CaregiverSelect from "../User/Select/Container"
import JobSelect from "../JobSelect/Container"

import { Rowz, Spacer } from "../Layout/Layout";

const ASSIGN_CAREGIVER_TO_JOB = gql`
  mutation assignCaregiverToJob($jobID: ID!, $caregiverID: ID!) {
    assignCaregiverToJob(jobID: $jobID, caregiverID: $caregiverID) {
      id
      firstName
      lastName
    }
  }
`;

class AssignCaregiverToJobForm extends React.Component {
  state = {
    jobID: null,
    caregiverID: null
  };

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <Mutation mutation={ASSIGN_CAREGIVER_TO_JOB}>
        {(assignCaregiverToJob, { data, loading, called, error }) => {
          if (called && !error && !loading) {
            if (data.assignCaregiverToJob) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      message: `Job successfully assigned to ${data.assignCaregiverToJob.firstName} ${data.assignCaregiverToJob.lastName}`
                    }
                  }}
                />
              );
            }
          }

          return (
            <React.Fragment>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  assignCaregiverToJob({ variables: this.state });
                }}
              >
                <Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Caregiver:</Text>
                      <CaregiverSelect filterRole="CAREGIVER" onChange={value => this.handleInputChange("caregiverID",parseInt(value))} />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Job:</Text>
                      <JobSelect onChange={value => this.handleInputChange("jobID",parseInt(value))} />
                    </Rowz>
                  </Spacer>
                  
                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Submit
                    </Button>
                  </Spacer>
                </Spacer>
              </form>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

export default AssignCaregiverToJobForm;
