import React from "react";
import { Query } from "react-apollo";
import { GET_REGION } from "../../../graphql";
import Profile from "./Profile";

const Container = ({ regionID }) => (
  <Query query={GET_REGION} variables={{ id: regionID }}>
    {({ loading, error, data }) => {
      if(loading) return <p>Loading...</p>;
      if(error) return <p>Error :(</p>;

      console.log("data.getREgion:" + JSON.stringify(data.getRegion,null,2))
      return <Profile region={data.getRegion} />;
    }}
  </Query>
);

export default Container;