import React from "react";
import { Query } from "react-apollo";
import { GET_ORGANIZATION } from "../../../graphql";
import Profile from "./Profile";

const Container = ({ organizationID }) => (
  <Query query={GET_ORGANIZATION} variables={{ id: organizationID }}>
    {({ loading, error, data }) => {
      if(loading) return <p>Loading...</p>;
      if(error) return <p>Error :(</p>;

      return <Profile organization={data.getOrganization} />;
    }}
  </Query>
);

export default Container;