import React from "react";
import { Mutation } from "react-apollo";
import { UPDATE_ORGANIZATION } from "../../../graphql";
import InputText from "../../UI/Input/Text";
import Text from "../../Type/Text";
import Button from "../../UI/Button";
import { Rowz, Spacer} from "../../Layout/Layout";

class OrganizationUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    const {
      name,
      email,
      address,
      postalCode,
      place,
      refNumber,
      phoneNumber,
    } = props.organizationData;


    this.state = {        
      name,
      email,
      address,
      postalCode,
      place,
      refNumber,
      phoneNumber,
    };

  }

  handleInputChange = (name, value) => {
    
      this.setState({
        [name]: value
      });
    
  };

  render() {    
    return (
      <Mutation mutation={UPDATE_ORGANIZATION}>
        {(updateOrganization, { data, called, error, loading }) => {
          if (called && !error && !loading) {
            window.location.reload();
          }
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                updateOrganization({ variables: { id: this.props.organizationID, input: this.state } });
              }}
            >
              <Spacer>
              <Spacer>
                    <Rowz>
                      <Text>Name:</Text>
                      <InputText
                        name="name"
                        placeholder="De Linde"
                        value={this.state.name || undefined}
                        required
                        onChange={value =>
                          this.handleInputChange("name", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Address:</Text>
                      <InputText
                        name="address"
                        value={this.state.address || undefined}
                        placeholder="Gedempte Oude Gracht"
                        required
                        onChange={value =>
                          this.handleInputChange("address", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Place:</Text>
                      <InputText
                        name="place"
                        placeholder="Zuid"
                        value={this.state.place || undefined}
                        required
                        onChange={value =>
                          this.handleInputChange("place", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  

                  <Spacer>
                    <Rowz>
                      <Text>Postal Code:</Text>
                      <InputText
                        name="postalCode"
                        placeholder="2011GM"
                        value={this.state.postalCode || undefined}
                        required
                        onChange={value =>
                          this.handleInputChange("postalCode", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  
                  <Spacer>
                    <Rowz>
                      <Text>Phone number:</Text>
                      <InputText
                        name="phoneNumber"
                        value={this.state.phoneNumber || undefined}
                        placeholder="0235575174"
                        required
                        onChange={value =>
                          this.handleInputChange("phoneNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Ref Number:</Text>
                      <InputText
                        name="refNumber"
                        value={this.state.refNumber || undefined}
                        placeholder="124315135"
                        required
                        onChange={value =>
                          this.handleInputChange("refNumber", value)
                        }
                      />
                    </Rowz>
                  </Spacer>
                  <Spacer>
                    <Rowz>
                      <Text>Email:</Text>
                      <InputText
                        name="email"
                        value={this.state.email || undefined}
                        placeholder="walter@sdl.nl"
                        required
                        onChange={value =>
                          this.handleInputChange("email", value)
                        }
                      />
                    </Rowz>
                  </Spacer> 

                  <Spacer>
                    <Button type="submit" disabled={loading}>
                      Update
                    </Button>
                  </Spacer>
              </Spacer>
            </form>
          );
        }}
      </Mutation>
    );
  }
}

export default OrganizationUpdateForm;
