import React from "react";
import LocationDeleteForm from "../../components/Location/DeleteForm/DeleteForm"
import RegionDeleteForm from "../../components/Region/DeleteForm/DeleteForm"
import PatientDeleteForm from "../../components/Patient/DeleteForm/DeleteForm"
import OrganizationDeleteForm from "../../components/Organization/DeleteForm/DeleteForm"
import Header from "../../components/Header";
import { Type, TextBold, TextSize } from "../../components/Type/Type";
import ProfileBannerContainer from "../../components/Profile/Banner/Container"


import {
  Rowz,
  Card,
  Wrapper,
  Spacer,
  Spacers,
  Tgrid,
  tbGrids,
} from "../../components/Layout/Layout";

const DeletePanel = () => (
  <React.Fragment>
           <Header/>
    <Rowz>
        <Wrapper>
          <Tgrid gr={tbGrids.grid20}>
            <Card>
              <Spacer>
                
                  <ProfileBannerContainer />
                
              </Spacer>
            </Card>
          </Tgrid>

          <Tgrid gr={tbGrids.grid80}>
            <Spacer spacer={Spacers.sLargeL}>
              <Card>
                <Spacer>
                <Type size={TextSize.lg} boldness={TextBold.bold}>
                  Delete panel
                </Type>
                </Spacer>
                <Spacer>
                <Type size={TextSize.lg} boldness={TextBold.bold}>
                  Location
                </Type>
                <LocationDeleteForm/>
                </Spacer>
                <Spacer>
                <Type size={TextSize.lg} boldness={TextBold.bold}>
                  Region
                </Type>
                <RegionDeleteForm/>
                </Spacer>
                <Spacer>
                <Type size={TextSize.lg} boldness={TextBold.bold}>
                  Organization
                </Type>
                <OrganizationDeleteForm/>
                </Spacer>
                <Spacer>
                <Type size={TextSize.lg} boldness={TextBold.bold}>
                  Patient
                </Type>
                <PatientDeleteForm/>
                </Spacer>
                
                
              
              </Card>
            </Spacer>
          </Tgrid>
        </Wrapper>
      </Rowz>
                           
            
  </React.Fragment>
)

export default DeletePanel;