import React, { useState } from "react";
import styles from "./Text.module.scss";

const Text = props => {
  const {
    value: defaultValue,
    parseValue = (v) => v,
    placeholder,
    password: isPassword,
    date: isDate,
    onChange,
    ...rest
  } = props;
  
  const [value, setValue] = useState(defaultValue || "");
  if(defaultValue !== null && defaultValue !== value) {
    setValue(defaultValue);
  }

  function handleChange(e) {
    const newValue = parseValue(e.target.value);

    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  }

  const type = isDate ? "date" : isPassword ? "password" : "text";

  
  return (
    <input
      {...rest}
      className={`${styles.tb_l_form__control}`}
      placeholder={placeholder}
      type={type}
      onChange={handleChange}
      value={value}
    />
  );
};

export default Text;
